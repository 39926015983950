import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../authContext';
import { REMOVE_CUSTOMER } from './queries/customerQueries';
import { useMutation } from '@apollo/client';
import Header2 from '../components/Header2';
import axios from 'axios';
import { Config } from '../config';

/**
 * Hier werden die Daten des Kunden gelöscht.
 */
const CustomerDeleteView = () => {
  const navigate = useNavigate();
  const [{ profile }, dispatch] = useAuth();
  const [deleteCustomerDialogVisible, setDeleteCustomerDialogVisible] =
    useState<boolean>(false);
  const NODEMAILER_URL: any =
    process.env.REACT_APP_NODEMAILER_URL !== undefined
      ? process.env.REACT_APP_NODEMAILER_URL
      : Config.nodemailer_url;

  // Kunden löschen
  const [removeCustomer, { loading, error }] = useMutation(REMOVE_CUSTOMER);

  /**
   * Dialog für das Löschen der Kundendaten
   */
  const handleCustomerDeleteDialog = async (action: string) => {
    if (action === 'delete') {
      setDeleteCustomerDialogVisible(false);
      if (localStorage.getItem('email') !== null) {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.removeItem('rememberMe');
      }
      try {
        const { data } = await removeCustomer({
          variables: {
            email: profile.email,
          },
        });
        if (data.delete_account.affected_rows === 1) {
          try {
            const response = await axios({
              baseURL: NODEMAILER_URL,
              method: 'POST',
              url: '/deletecustomer',
              data: {
                email: profile.email,
                subject: 'Account gelöscht',
              },
            });
            if (response.data.status === 'success') {
              await dispatch({
                type: 'LOGOUT',
              });
              navigate('/start');
            }
          } catch (error) {
            console.log('Error! Unable to send Email: ', error);
          }
        }
      } catch (error) {
        console.log('Error! CustomerDeleteView - removeAccount:', error);
      }
    } else {
      setDeleteCustomerDialogVisible(false);
      navigate('/customer');
    }
  };

  // Beim laden nichts anzeigen.
  if (loading) {
    return null;
  }

  // Falls Fehler beim Löschen auftreten.
  if (error) {
    console.log(`Error! CustomerDeleteView: ${error.message}`);
  }

  // return
  return (
    <Container maxWidth='xs'>
      <Dialog
        open={deleteCustomerDialogVisible}
        onClose={() => setDeleteCustomerDialogVisible(false)}
        aria-labelledby='delete-customer-dialog-title'
        aria-describedby='delete-customer-dialog-description'
      >
        <DialogTitle id='delete-customer-dialog-title'>
          Sollen Ihr Account und die Daten gelöscht werden?
        </DialogTitle>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => handleCustomerDeleteDialog('cancel')}
            variant='outlined'
            color='inherit'
          >
            Abbrechen
          </Button>
          <Button
            onClick={() => handleCustomerDeleteDialog('delete')}
            variant='outlined'
            color='inherit'
            autoFocus
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
      <Box mt={3}>
        <Header2
          title='Account löschen'
          subtitle={`Sie sind angemeldet als ${profile.email}`}
        />
      </Box>
      <Box mt={4}>
        <Typography
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Hier können Sie Ihren Account und alle Ihre Daten löschen.
        </Typography>
      </Box>
      <Box
        mt={3}
        mb={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            borderRadius: 30,
            width: '240px',
          }}
          type='submit'
          variant='contained'
          color='secondary'
          onClick={() => setDeleteCustomerDialogVisible(true)}
        >
          Account löschen
        </Button>
        <Button
          sx={{
            marginTop: 2,
            borderRadius: 30,
            width: '240px',
          }}
          type='submit'
          variant='contained'
          color='secondary'
          onClick={() => navigate('/customer')}
        >
          Abbrechen
        </Button>
      </Box>
    </Container>
  );
};

export default CustomerDeleteView;
