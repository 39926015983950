import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  FormControl,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GET_ACCOUNT } from '../data/AccountData';
import {
  ADD_PASSWORD_CODE,
  REMOVE_PASSWORD_CODE,
} from './queries/loginQueries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IInputError } from '../interfaces/interfaces';
import Header from '../components/Header';
import axios from 'axios';
import { Config } from '../config';

/**
 * ForgotPasswordScreen
 */
const ForgotPasswordView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [isButtonSendEnabled, setIsButtonSendEnabled] =
    useState<boolean>(false);
  const NODEMAILER_URL: any =
    process.env.REACT_APP_NODEMAILER_URL !== undefined
      ? process.env.REACT_APP_NODEMAILER_URL
      : Config.nodemailer_url;

  // account überprüfen ob vonhanden ist
  const [checkAccount] = useLazyQuery(GET_ACCOUNT);

  // removePasswordcode
  const [removePassordcode] = useMutation(REMOVE_PASSWORD_CODE);

  // addPasswordcode
  const [addPassordcode] = useMutation(ADD_PASSWORD_CODE);

  /**
   * Button Senden Status setzen.
   */
  useEffect(() => {
    if (email === '' || emailError.isError === true) {
      setIsButtonSendEnabled(false);
    } else {
      setIsButtonSendEnabled(true);
    }
  }, [email, emailError.isError]);

  /**
   * Email error wird zurückgesetzt.
   */
  const handleEmailOnFocus = () => {
    setEmailError({
      isError: false,
      message: '',
    });
  };

  /**
   * Set Email
   */
  const handleEmailInput = (e: any) => {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setEmail(e.target.value);
    if (regexp.test(e.target.value)) {
      setEmailError({
        isError: false,
        message: '',
      });
    } else {
      setEmailError({
        isError: true,
        message: '',
      });
    }
  };

  /**
   * Funktion zur Validierung der Email.
   */
  const checkEmailInput = async () => {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === '') {
      setEmailError({
        isError: true,
        message: 'E-Mail-Adresse ist erforderlich!',
      });
    } else if (regexp.test(email)) {
      // this is a valid email address
      // überprüfen ob die email vorhanden ist
      try {
        const { data } = await checkAccount({
          variables: { email },
          fetchPolicy: 'network-only',
        });
        if (data.account.length !== 0) {
          // email ist ok, und vorhanden
          setEmailError({
            isError: false,
            message: '',
          });
        } else {
          // email ist ok, aber nicht vorhanden
          setEmailError({
            isError: true,
            message: 'Diese E-Mail-Adresse ist nicht vorhanden.',
          });
        }
      } catch (error) {
        console.log('Error! ForgotPasswordView - checkEmailInput:', error);
      }
    } else {
      // email ist nicht leer, aber nicht ok
      // invalid email, maybe show an error to the user.
      setEmailError({
        isError: true,
        message: 'Ist keine gültige E-Mail-Adresse.',
      });
    }
  };

  /**
   * Funktion zum versenden einer E-Mail
   * mit dem Password code.
   */
  const handleForgotPassword = async () => {
    const code = Math.floor(Math.random() * 9000 + 1000).toString();
    // In der Datenbank löschen, falls vorhanden
    try {
      await removePassordcode({ variables: { email } });
      // In die Datenbank schreiben
      try {
        await addPassordcode({ variables: { passwordcode: { email, code } } });
        // An den nodemailer senden
        try {
          const response = await axios({
            baseURL: NODEMAILER_URL,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            url: '/passwordcode',
            data: {
              email,
              subject: 'Passwort vergessen',
              message: code,
            },
          });
          if (response.data.status === 'success') {
            setEmail('');
            navigate('/resetPassword', {
              state: {
                email: email,
              },
            });
          }
        } catch (error) {
          console.log('Error! Unable to send passwordcode: ', error);
        }
      } catch (error) {
        console.log('Error! Unable to write passwordcode: ', error);
      }
    } catch (error) {
      console.log('Error! Unable to remove passwordcode: ', error);
    }
  };

  // return
  return (
    <Container maxWidth='xs'>
      <Box mt={3} mb={2}>
        <Header
          title='Passwort vergessen?'
          subtitle='Geben Sie unten Ihre E-Mail-Adresse ein, die Sie für die Anmeldung benutzen.'
        />
      </Box>
      <Box>
        <FormControl required sx={{ width: '100%' }}>
          <TextField
            id='email'
            name='email'
            sx={{ m: theme.spacing(1, 0, 1) }}
            inputProps={{
              sx: {
                '&:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset`,
                  WebkitTextFillColor: theme.palette.text.primary,
                },
              },
            }}
            variant='standard'
            color='secondary'
            margin='dense'
            required
            label='E-Mail-Adresse'
            value={email}
            autoComplete='email'
            error={emailError.isError}
            helperText={emailError.message}
            FormHelperTextProps={{
              sx: { color: theme.palette.secondary.main },
            }}
            onFocus={handleEmailOnFocus}
            onChange={(e) => handleEmailInput(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                checkEmailInput();
              }
            }}
            onBlur={checkEmailInput}
          />
        </FormControl>
      </Box>
      <Box mt={2} mb={2}>
        <Box
          mt={2}
          mb={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button
            type='submit'
            sx={{
              mt: 2,
              borderRadius: 30,
              width: '240px',
            }}
            disabled={!isButtonSendEnabled}
            variant='contained'
            color='secondary'
            onClick={() => handleForgotPassword()}
          >
            Senden
          </Button>
          <Button
            sx={{
              mt: 2,
              borderRadius: 30,
              width: '240px',
            }}
            type='submit'
            variant='contained'
            color='secondary'
            onClick={() => navigate('/login')}
          >
            Abbrechen
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPasswordView;
