import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Avatar,
  IconButton,
  Badge,
} from '@mui/material';
import { IoNewspaperOutline } from 'react-icons/io5';
import {
  AiOutlineMessage,
  AiOutlineLogin,
  AiOutlineClose,
} from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../authContext';
import Header2 from '../components/Header2';
import { SUBSCRIBE_MESSAGEGROUPS } from '../data/MessagesData';
import { SUBSCRIBE_NEWS } from '../data/NewsData';
import { useSubscription } from '@apollo/client';

/**
 * Startview des Customers
 */
const CustomerView = () => {
  const navigate = useNavigate();
  const [{ profile }] = useAuth();
  const [messageGroupsNumber, setMessageGroupsNumber] = useState<number>(0);
  const [newsNumber, setNewsNumber] = useState<number>(0);

  // subscribe message groups
  const {
    loading: messageGroupsLoading,
    error: messageGroupsError,
    data: messageGroupsData,
  } = useSubscription(SUBSCRIBE_MESSAGEGROUPS, {
    variables: { email: profile.email },
  });

  // news subscription
  const {
    loading: newsLoading,
    error: newsError,
    data: newsData,
  } = useSubscription(SUBSCRIBE_NEWS, {
    variables: { email: profile.email },
  });

  // messageGroups Anzahl setzen
  useEffect(() => {
    const items: any = [];
    if (messageGroupsData) {
      for (let i = 0; i < messageGroupsData.message.length; i++) {
        if (messageGroupsData.message[i].status === 'tenantanswered') {
          items.push(messageGroupsData.message[i].status);
        }
      }
    }
    setMessageGroupsNumber(items.length);
  }, [messageGroupsData]);

  // news Anzahl setzen
  useEffect(() => {
    const items: any = [];
    if (newsData) {
      for (let i = 0; i < newsData.news.length; i++) {
        if (newsData.news[i].status === 'new') {
          items.push(newsData.news[i].status);
        }
      }
    }
    setNewsNumber(items.length);
  }, [newsData]);

  // Beim laden nichts anzeigen.
  if (messageGroupsLoading) {
    return null;
  }

  // Falls Fehler auftritt.
  if (messageGroupsError) {
    console.log(`Error! CustomerView: ${messageGroupsError.message}`);
  }

  // Beim laden nichts anzeigen.
  if (newsLoading) {
    return null;
  }

  // Falls Fehler auftritt.
  if (newsError) {
    console.log(`Error! CustomerView: ${newsError.message}`);
  }

  // return
  return (
    <Container maxWidth='xs'>
      <Box mt={3}>
        <Header2
          title='Account'
          subtitle={`Sie sind angemeldet als ${profile.email}`}
        />
      </Box>
      <Box mt={1} sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 100,
              width: 150,
            }}
          >
            {messageGroupsData.message.length === 0 ? (
              <>
                <Avatar style={{ backgroundColor: '#00E676' }}>
                  <IconButton
                    onClick={() => navigate('/customerMessagesGroups')}
                  >
                    <AiOutlineMessage size='22' />
                  </IconButton>
                </Avatar>
                <Typography variant='subtitle2' mt={1}>
                  Nachrichten
                </Typography>
              </>
            ) : (
              <>
                <Badge
                  color='secondary'
                  badgeContent={messageGroupsNumber}
                  overlap='circular'
                >
                  <Avatar style={{ backgroundColor: '#00E676' }}>
                    <IconButton
                      onClick={() => navigate('/customerMessagesGroups')}
                    >
                      <AiOutlineMessage size='22' />
                    </IconButton>
                  </Avatar>
                </Badge>
                <Typography variant='subtitle2' mt={1}>
                  Nachrichten
                </Typography>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 100,
              width: 150,
            }}
          >
            {newsData.news.length === 0 ? (
              <>
                <Avatar style={{ backgroundColor: '#FF4081' }}>
                  <IconButton onClick={() => navigate('/customerNews')}>
                    <IoNewspaperOutline size='22' />
                  </IconButton>
                </Avatar>
                <Typography variant='subtitle2' mt={1}>
                  News
                </Typography>
              </>
            ) : (
              <>
                <Badge
                  color='secondary'
                  badgeContent={newsNumber}
                  overlap='circular'
                >
                  <Avatar style={{ backgroundColor: '#FF4081' }}>
                    <IconButton onClick={() => navigate('/customerNews')}>
                      <IoNewspaperOutline size='22' />
                    </IconButton>
                  </Avatar>
                </Badge>
                <Typography variant='subtitle2' mt={1}>
                  News
                </Typography>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 100,
              width: 150,
            }}
          >
            <Avatar style={{ backgroundColor: '#FF6D00' }}>
              <IconButton onClick={() => navigate('/customerLogindata')}>
                <AiOutlineLogin size='22' />
              </IconButton>
            </Avatar>
            <Typography variant='subtitle2' mt={1}>
              Logindaten
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 100,
              width: 150,
            }}
          >
            <Avatar style={{ backgroundColor: '#ff1744' }}>
              <IconButton onClick={() => navigate('/customerDelete')}>
                <AiOutlineClose size='22' />
              </IconButton>
            </Avatar>
            <Typography variant='subtitle2' mt={1}>
              Account löschen
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default CustomerView;
