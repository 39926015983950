import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Avatar,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Badge,
} from '@mui/material';
import {
  AiOutlineMessage,
  AiOutlineUsergroupAdd,
  AiOutlineStar,
  AiOutlineUnorderedList,
  AiOutlineHome,
  AiOutlineLogin,
  AiOutlineDelete,
  AiOutlineContacts,
  AiOutlineFilePdf,
  AiOutlineQuestionCircle,
  AiOutlineClose,
} from 'react-icons/ai';
import { GET_STORE_ID_BY_TENANT_ID } from '../data/StoreData';
import {
  SUBSCRIBE_STORE_TICKETS,
  CLOSE_TICKET_BY_ID,
  GET_STORE_OPEN_INVOICES,
} from './queries/tenantQueries';
import { SUBSCRIBE_MESSAGEGROUPS } from '../data/MessagesData';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/de';
import { useAuth } from '../authContext';
import { useQuery, useMutation, useSubscription } from '@apollo/client';
import Header2 from '../components/Header2';
import { Config } from '../config';
import TenantHelpView from './help/TenantHelpView';

/**
 * Startview des Tenants
 */
const TenantView = () => {
  const navigate = useNavigate();
  const [{ profile }] = useAuth();
  const [ticketDeleteDialogVisible, setTicketDeleteDialogVisible] =
    useState<boolean>(false);
  const theme = useTheme();
  const routerLocation: any = useRouterLocation();
  const [storeId, setStoreId] = useState<number>(0);
  const [helpOpen, setHelpOpen] = useState<boolean>(
    routerLocation.state === null ? false : routerLocation.state.helpopen
  );
  const [currentTicket, setCurrentTicket] = useState<Partial<any>>({
    id: 0,
    subject: '',
    ticket: '',
  });
  const [messageGroupsNumber, setMessageGroupsNumber] = useState<number>(0);

  // media url
  const MEDIA_URL: any =
    process.env.REACT_APP_MINIO_ENDPOINT !== undefined
      ? process.env.REACT_APP_MINIO_ENDPOINT
      : Config.minio_endpoint;

  // get store id
  const {
    loading: storeLoading,
    error: storeError,
    data: storeData,
  } = useQuery(GET_STORE_ID_BY_TENANT_ID, {
    variables: { tenantid: profile.tenant_id },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setStoreId(data.store[0].id);
    },
  });

  // get store tickets
  const {
    loading: storeTicketsLoading,
    error: storeTicketsError,
    data: storeTicketsData,
  } = useSubscription(SUBSCRIBE_STORE_TICKETS, {
    variables: { storeid: storeId, status: 'closed' },
    skip: storeId === 0,
  });

  // close ticket
  const [closeTicket] = useMutation(CLOSE_TICKET_BY_ID, {
    onError: (error) => {
      console.log(`Error! TenantView - closeTicket: ${error.message}`);
    },
  });

  // get open invoices
  const {
    loading: invoicesLoading,
    error: invoicesError,
    data: invoicesData,
  } = useQuery(GET_STORE_OPEN_INVOICES, {
    variables: {
      storeid: storeId,
    },
    fetchPolicy: 'network-only',
  });

  // subscribe message groups
  const {
    loading: messageGroupsLoading,
    error: messageGroupsError,
    data: messageGroupsData,
  } = useSubscription(SUBSCRIBE_MESSAGEGROUPS, {
    variables: { email: profile.email },
  });

  moment().locale('de');

  // messageGroups Anzahl setzen
  useEffect(() => {
    const items: any = [];
    if (messageGroupsData) {
      for (let i = 0; i < messageGroupsData.message.length; i++) {
        if (
          messageGroupsData.message[i].status === 'customeranswered' ||
          messageGroupsData.message[i].status === 'new'
        ) {
          items.push(messageGroupsData.message[i].status);
        }
      }
    }
    setMessageGroupsNumber(items.length);
  }, [messageGroupsData]);

  // Beim laden der Daten nichts anzeigen.
  if (storeLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (storeError) {
    console.log(`Error! TenantView - store: ${storeError.message}`);
  }

  // Beim laden der Daten nichts anzeigen.
  if (storeTicketsLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (storeTicketsError) {
    console.log(
      `Error! TenantView - storeTickets: ${storeTicketsError.message}`
    );
  }

  // Beim laden der Daten nichts anzeigen.
  if (invoicesLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (invoicesError) {
    console.log(`Error! TenantView - invoices: ${invoicesError.message}`);
  }

  // Beim laden nichts anzeigen.
  if (messageGroupsLoading) {
    return null;
  }

  // Falls Fehler auftritt.
  if (messageGroupsError) {
    console.log(`Error! TenantView: ${messageGroupsError.message}`);
  }

  // return
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
      style={{ width: '100%' }}
    >
      <Container maxWidth='xs'>
        <Dialog
          open={ticketDeleteDialogVisible}
          onClose={() => setTicketDeleteDialogVisible(false)}
          aria-labelledby='delete-ticket-dialog-title'
          aria-describedby='delete-ticket-dialog-description'
        >
          <DialogTitle id='delete-ticket-dialog-title'>
            Soll das Ticket gelöscht werden?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='delete-ticket-dialog-description'>
              {' "'}
              {currentTicket.subject}
              {'" '}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => {
                setTicketDeleteDialogVisible(false);
              }}
              variant='outlined'
              color='inherit'
            >
              Abbrechen
            </Button>
            <Button
              onClick={() => {
                closeTicket({
                  variables: {
                    id: currentTicket.id,
                    status: 'closed',
                    closed: new Date(
                      Date.now() + 1000 * 60 * -new Date().getTimezoneOffset()
                    )
                      .toISOString()
                      .replace('T', ' ')
                      .replace('Z', ''),
                  },
                });
                setTicketDeleteDialogVisible(false);
              }}
              color='secondary'
              variant='outlined'
              autoFocus
            >
              Löschen
            </Button>
          </DialogActions>
        </Dialog>
        {/** Header */}
        <Box mt={3}>
          <Header2
            title='Account'
            subtitle={`Sie sind angemeldet als ${profile.email}`}
          />
        </Box>
        {/** Header end */}
        {/** Content */}
        <Box mt={1}>
          {/** Grid */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
            }}
          >
            {/** Grid Cell */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 100,
                width: 110,
              }}
            >
              {messageGroupsData.message.length === 0 ? (
                <>
                  <Avatar style={{ backgroundColor: '#00E676' }}>
                    <IconButton
                      onClick={() =>
                        navigate('/tenantMessagesGroups', {
                          state: { helpopen: helpOpen },
                        })
                      }
                    >
                      <AiOutlineMessage size='22' />
                    </IconButton>
                  </Avatar>
                  <Typography variant='subtitle2' style={{ marginTop: 8 }}>
                    Nachrichten
                  </Typography>
                </>
              ) : (
                <>
                  <Badge
                    color='secondary'
                    badgeContent={messageGroupsNumber}
                    overlap='circular'
                  >
                    <Avatar style={{ backgroundColor: '#00E676' }}>
                      <IconButton
                        onClick={() =>
                          navigate('/tenantMessagesGroups', {
                            state: { helpopen: helpOpen },
                          })
                        }
                      >
                        <AiOutlineMessage size='22' />
                      </IconButton>
                    </Avatar>
                  </Badge>
                  <Typography variant='subtitle2' style={{ marginTop: 8 }}>
                    Nachrichten
                  </Typography>
                </>
              )}
            </Box>
            {/** Grid Cell end */}
            {/** Grid Cell */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 100,
                width: 110,
              }}
            >
              <Avatar style={{ backgroundColor: '#FF4081' }}>
                <IconButton
                  onClick={() =>
                    navigate('/tenantRegularCustomer', {
                      state: {
                        storeId: storeData.store[0].id,
                        helpopen: helpOpen,
                      },
                    })
                  }
                >
                  <AiOutlineUsergroupAdd size='22' />
                </IconButton>
              </Avatar>
              <Typography variant='subtitle2' style={{ marginTop: 8 }}>
                Stammkunden
              </Typography>
            </Box>
            {/** Grid Cell end */}
            {/** Grid Cell */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 100,
                width: 110,
              }}
            >
              <Avatar style={{ backgroundColor: '#AB47BC' }}>
                <IconButton
                  onClick={() =>
                    navigate('/tenantProductAssortment', {
                      state: {
                        storeId: storeData.store[0].id,
                        helpopen: helpOpen,
                      },
                    })
                  }
                >
                  <AiOutlineStar size='22' />
                </IconButton>
              </Avatar>
              <Typography variant='subtitle2' style={{ marginTop: 8 }}>
                Sortiment
              </Typography>
            </Box>
            {/** Grid Cell end */}
            {/** Grid Cell */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 100,
                width: 110,
              }}
            >
              <Avatar style={{ backgroundColor: '#2979FF' }}>
                <IconButton
                  onClick={() =>
                    navigate('/tenantProductCategories', {
                      state: {
                        storeId: storeData.store[0].id,
                        helpopen: helpOpen,
                      },
                    })
                  }
                >
                  <AiOutlineUnorderedList size='22' />
                </IconButton>
              </Avatar>
              <Typography variant='subtitle2' style={{ marginTop: 8 }}>
                Warengruppen
              </Typography>
            </Box>
            {/** Grid Cell end */}
            {/** Grid Cell */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 100,
                width: 110,
              }}
            >
              <Avatar style={{ backgroundColor: '#90A4AE' }}>
                <IconButton
                  onClick={() =>
                    navigate('/tenantStore', {
                      state: { helpopen: helpOpen },
                    })
                  }
                >
                  <AiOutlineHome size='22' />
                </IconButton>
              </Avatar>
              <Typography variant='subtitle2' style={{ marginTop: 8 }}>
                Geschäftsdaten
              </Typography>
            </Box>
            {/** Grid Cell end */}
            {/** Grid Cell */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 100,
                width: 110,
              }}
            >
              <Avatar style={{ backgroundColor: '#FF6D00' }}>
                <IconButton
                  onClick={() =>
                    navigate('/tenantLogindata', {
                      state: { helpopen: helpOpen },
                    })
                  }
                >
                  <AiOutlineLogin size='22' />
                </IconButton>
              </Avatar>
              <Typography variant='subtitle2' style={{ marginTop: 8 }}>
                Logindaten
              </Typography>
            </Box>
            {/** Grid Cell end */}
            {/** Grid Cell */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 100,
                width: 110,
              }}
            >
              <Avatar style={{ backgroundColor: '#F44336' }}>
                <IconButton
                  onClick={() =>
                    navigate('/tenantContract', {
                      state: { helpopen: helpOpen },
                    })
                  }
                >
                  <AiOutlineContacts size='22' />
                </IconButton>
              </Avatar>
              <Typography variant='subtitle2' style={{ marginTop: 8 }}>
                Vertragsdaten
              </Typography>
            </Box>
            {/** Grid Cell end */}
          </Box>
          {/** Grid end */}
        </Box>
        {/** Content end */}
        <Box
          mt={3}
          mb={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: 1,
            borderColor:
              theme.palette.mode === 'dark'
                ? theme.palette.primary.main
                : theme.palette.text.disabled,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: theme.palette.primary.main,
              borderBottom: 0.5,
              borderColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.primary.main
                  : theme.palette.text.disabled,
              width: '100%',
              height: '32px',
            }}
          >
            <Typography>Offene Tickets</Typography>
          </Box>
          {storeTicketsData !== undefined &&
          storeTicketsData.ticket.length > 0 ? (
            <List component='div' disablePadding dense sx={{ width: '100%' }}>
              {storeTicketsData.ticket.map((ticket: any) => (
                <ListItemButton
                  key={ticket.id}
                  sx={{
                    pl: 2,
                    pr: 2,
                    border: 0.5,
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    borderColor:
                      theme.palette.mode === 'dark'
                        ? theme.palette.primary.main
                        : theme.palette.text.disabled,
                  }}
                  disableGutters
                  onClick={() => {
                    setCurrentTicket({
                      id: ticket.id,
                      subject: ticket.subject,
                      ticket: ticket.ticket,
                    });
                    navigate('/tenantTicket', {
                      state: {
                        id: ticket.id,
                        status: ticket.status,
                        subject: ticket.subject,
                        ticket: ticket.ticket,
                        answer: ticket.answer,
                      },
                    });
                  }}
                >
                  <ListItemText
                    primary={ticket.subject}
                    primaryTypographyProps={{ align: 'left' }}
                  />
                  <ListItemText
                    sx={{ mr: 4 }}
                    primary={ticket.status === 'open' ? 'offen' : 'beantwortet'}
                    primaryTypographyProps={{ align: 'right' }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge='end'
                      aria-label='delete'
                      sx={{ mr: 0.5 }}
                      onClick={() => {
                        setCurrentTicket({
                          id: ticket.id,
                          subject: ticket.subject,
                          ticket: ticket.ticket,
                        });
                        setTicketDeleteDialogVisible(true);
                      }}
                    >
                      <AiOutlineDelete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItemButton>
              ))}
            </List>
          ) : (
            <Box
              sx={{
                paddingLeft: 2,
                paddingTop: 0.8,
                paddingBottom: 0.8,
                alignSelf: 'flex-start',
                height: '32px',
              }}
            >
              <Typography>Keine vorhanden!</Typography>
            </Box>
          )}
          <Box mt={0.8} mb={0.8}>
            <Button
              type='submit'
              variant='contained'
              color='secondary'
              sx={{ borderRadius: 30, width: '140px', height: '26px' }}
              onClick={() =>
                navigate('/tenantTicketNew', {
                  state: {
                    storeid: storeData.store[0].id,
                  },
                })
              }
            >
              Neues Ticket
            </Button>
          </Box>
        </Box>
        <Box
          mt={4}
          mb={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            border: 1,
            borderColor:
              theme.palette.mode === 'dark'
                ? theme.palette.primary.main
                : theme.palette.text.disabled,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: theme.palette.primary.main,
              borderBottom: 0.5,
              borderColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.primary.main
                  : theme.palette.text.secondary,
              width: '100%',
              height: '32px',
            }}
          >
            <Typography>Offene Rechnungen</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {invoicesData !== undefined && invoicesData.invoice.length > 0 ? (
              <List
                component='div'
                disablePadding
                dense
                sx={{
                  width: '100%',
                }}
              >
                {invoicesData.invoice.map((invoice: any) => (
                  <Box key={invoice.id}>
                    <a
                      href={`${
                        MEDIA_URL +
                        '/' +
                        invoice.store_id +
                        '-data/Rechnung-' +
                        invoice.store_id +
                        '-' +
                        invoice.id +
                        '-' +
                        moment(invoice.invoicedate).format('L') +
                        '.pdf'
                      }`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          '&:hover, &:focus, &:active': {
                            backgroundColor:
                              theme.palette.mode === 'dark'
                                ? theme.palette.primary.light
                                : theme.palette.grey[200],
                          },
                        }}
                      >
                        <ListItemText
                          sx={{
                            paddingLeft: 2,
                            color: theme.palette.text.primary,
                            '&:hover, &:focus, &:active': {
                              textDecoration: `underline ${theme.palette.text.primary}`,
                            },
                          }}
                        >
                          Rechnung vom:{' '}
                          {moment(invoice.invoicedate).format('L')}{' '}
                        </ListItemText>
                        <ListItemText
                          sx={{ mr: 1, color: theme.palette.text.primary }}
                          primary={
                            invoice.status !== 'billpaid' ? 'offen' : null
                          }
                          primaryTypographyProps={{ align: 'right' }}
                        />
                        <IconButton>
                          {' '}
                          <AiOutlineFilePdf />
                        </IconButton>
                      </Box>
                    </a>
                  </Box>
                ))}
              </List>
            ) : (
              <Box
                sx={{
                  paddingLeft: 2,
                  paddingRight: 2,
                  paddingTop: 0.8,
                  paddingBottom: 0.8,
                  alignSelf: 'flex-start',
                  height: '32px',
                }}
              >
                <Typography>Keine vorhanden!</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
      {helpOpen ? (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
          }}
          style={{ backgroundColor: '#9E9E9E', width: '20%' }}
        >
          <Box
            sx={{
              paddingTop: 1,
              paddingLeft: 1.5,
            }}
          >
            <IconButton onClick={() => setHelpOpen(false)}>
              <AiOutlineClose size='24' />
            </IconButton>
          </Box>
          <TenantHelpView />
        </Box>
      ) : (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
            paddingRight: 1.5,
            paddingTop: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => setHelpOpen(true)}>
              <AiOutlineQuestionCircle size='24' />
            </IconButton>
            <Typography
              onClick={() => setHelpOpen(true)}
              paragraph
              component='span'
              sx={{
                color: theme.palette.text.primary,
                '&:hover, &:focus, &:active': {
                  textDecoration: `underline ${theme.palette.text.primary}`,
                },
              }}
            >
              Hilfe
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TenantView;
