import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Container,
  AppBar,
  Box,
  Toolbar,
  Typography,
  FormControl,
  TextField,
  List,
  ListItemText,
  Menu,
  MenuItem,
  Button,
  IconButton,
  InputAdornment,
  Link,
  Divider,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListItemButton,
  Collapse,
  Badge,
} from '@mui/material';
import {
  Link as RouterLink,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import {
  AiOutlineMenu,
  AiOutlineSearch,
  AiOutlineEnvironment,
  AiOutlineLogin,
  AiOutlineLogout,
  AiOutlineUser,
  AiOutlineClose,
  AiOutlineFilter,
  AiOutlineInfoCircle,
} from 'react-icons/ai';
import { BiAdjust } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useLocation } from '../../locationContext';
import { useAuth } from '../../authContext';
import { ThemeContext } from '../../themeContext';
import {
  GET_LOCATION_BRANCHES,
  GET_BRANCH_STORES,
} from '../../data/BranchesData';
import { GET_ACCOUNT } from '../../data/AccountData';
import { SUBSCRIBE_MESSAGEGROUPS } from '../../data/MessagesData';
import { SUBSCRIBE_NEWS } from '../../data/NewsData';
import { useQuery, useLazyQuery, useSubscription } from '@apollo/client';

/**
 * Topbar Component
 */
const Topbar = (props: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [{ location }] = useLocation();
  const routerLocation: any = useRouterLocation();
  const [{ isAuthenticated, profile }, dispatch] = useAuth();
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
  const [searchBarIsOpen, setSearchBarIsOpen] = useState<boolean>(false);
  const [searchParam, setSearchParam] = useState<string>('');
  const [searchDialogVisible, setSearchDialogVisible] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const anchorRef = useRef<null | HTMLDivElement>(null);
  const rememberMeStatus: string | null = localStorage.getItem('rememberMe');
  const localStorageEmail: string | null = localStorage.getItem('email');
  const [notificationsNumber, setNotificationsNumber] = useState<number>(0);

  // get location branches
  const {
    loading: branchesLoading,
    error: branchesError,
    data: branchesData,
  } = useQuery(GET_LOCATION_BRANCHES, {
    variables: { locations: `{${location.locationlist.toString()}}` },
    fetchPolicy: 'network-only',
  });

  // get branch stores
  const {
    loading: storesLoading,
    error: storesError,
    data: storesData,
  } = useQuery(GET_BRANCH_STORES, {
    variables: {
      locations: `{${location.locationlist.toString()}}`,
      storebranchid:
        props.childPagePropsFromParent !== undefined &&
        props.childPagePropsFromParent.branchid !== undefined
          ? props.childPagePropsFromParent.branchid
          : 0,
    },
    fetchPolicy: 'network-only',
  });

  // account profile holen
  const [getAccount, { data }] = useLazyQuery(GET_ACCOUNT, {
    variables: { email: localStorageEmail },
  });

  // news subscription
  const {
    loading: newsLoading,
    error: newsError,
    data: newsData,
  } = useSubscription(SUBSCRIBE_NEWS, {
    skip: localStorageEmail === null || rememberMeStatus === 'false',
    //skip: true,
    variables: { email: localStorageEmail },
  });

  // messageGroups subscription
  const {
    loading: messageGroupsLoading,
    error: messageGroupsError,
    data: messageGroupsData,
  } = useSubscription(SUBSCRIBE_MESSAGEGROUPS, {
    skip: rememberMeStatus === 'false',
    variables: { email: localStorageEmail },
  });

  //localStorage.clear();
  //localStorage.removeItem('email');
  //localStorage.removeItem('password');
  //localStorage.removeItem('rememberMe');

  console.log('localStorage', JSON.stringify(localStorage));
  console.log('localStorageEmail: ', localStorageEmail);

  // localStorage nach rememberMe abfragen. Falls 'true' (String nicht Boolean!)
  // können news (beim customer) und messages (bei customer und tenant)
  // subscriptins benutzt werden.
  useEffect(() => {
    if (rememberMeStatus === 'true') {
      getAccount();
      if (data) {
        if (data.account[0].userrole === 'customer') {
          const newsItems: any = [];
          if (newsData) {
            for (let i = 0; i < newsData.news.length; i++) {
              if (newsData.news[i].status === 'new') {
                newsItems.push(newsData.news[i].status);
              }
            }
          }
          const messagesGroupsItems: any = [];
          if (messageGroupsData) {
            for (let i = 0; i < messageGroupsData.message.length; i++) {
              if (messageGroupsData.message[i].status === 'tenantanswered') {
                messagesGroupsItems.push(messageGroupsData.message[i].status);
              }
            }
          }
          setNotificationsNumber(newsItems.length + messagesGroupsItems.length);
        } else if (data.account[0].userrole === 'tenant') {
          const messageGroupsItems: any = [];
          if (messageGroupsData) {
            for (let i = 0; i < messageGroupsData.message.length; i++) {
              if (
                messageGroupsData.message[i].status === 'customeranswered' ||
                messageGroupsData.message[i].status === 'new'
              ) {
                messageGroupsItems.push(messageGroupsData.message[i].status);
              }
            }
          }
          setNotificationsNumber(messageGroupsItems.length);
        }
      }
    } else {
      setNotificationsNumber(0);
    }
  }, [
    data,
    getAccount,
    localStorageEmail,
    messageGroupsData,
    newsData,
    rememberMeStatus,
  ]);

  /**
   * Drawer status setzen
   */
  useEffect(() => {
    if (
      routerLocation.state !== null &&
      routerLocation.state.drawerisopen !== undefined
    ) {
      setDrawerIsOpen(routerLocation.state.drawerisopen);
    }
  }, [routerLocation.state]);

  /**
   * searchParam setzen
   */
  useEffect(() => {
    if (
      routerLocation.state !== null &&
      routerLocation.state.searchparam !== undefined
    ) {
      setSearchParam(routerLocation.state.searchparam);
    }
  }, [routerLocation.state]);

  /**
   * Beim laden nichts anzeigen.
   */
  if (branchesLoading) {
    return null;
  }

  /**
   * Falls Fehler bei der Abfrage auftritt.
   */
  if (branchesError) {
    console.log(`Error! SideBar: ${branchesError.message}`);
  }

  /**
   * Beim laden nichts anzeigen.
   */
  if (storesLoading) {
    return null;
  }

  /**
   * Falls Fehler bei der Abfrage auftritt.
   */
  if (storesError) {
    console.log(`Error! SideBar: ${storesError.message}`);
  }

  // Beim laden nichts anzeigen.
  if (newsLoading) {
    return null;
  }

  // Falls Fehler auftritt.
  if (newsError) {
    console.log(`Error! TopBar: ${newsError.message}`);
  }

  // Beim laden nichts anzeigen.
  if (messageGroupsLoading) {
    return null;
  }

  // Falls Fehler auftritt.
  if (messageGroupsError) {
    console.log(`Error! TopBar: ${messageGroupsError.message}`);
  }

  /**
   * Logout
   */
  const doLogout = () => {
    dispatch({
      type: 'LOGOUT',
    });
  };

  /**
   * checkSearchParam
   */
  const checkSearchParam = (event: any) => {
    if (searchParam.length >= 3) {
      setAnchorEl(event.currentTarget);
    } else {
      setSearchDialogVisible(true);
    }
  };

  /**
   * handleSearchChange
   */
  const handleSearchChange = (event: any) => {
    setSearchParam(event.target.value);
    if (props.childPagePropsFromParent !== undefined) {
      props.sendChildPagePropsToParent({
        ...props.childPagePropsFromParent,
        searchparam: searchParam,
      });
    }
    if (event.target.value.length >= 3) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  /**
   * Menu close
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Navigation zum Filter mit Parametern.
   */
  const goToFilter = () => {
    props.childPagePropsFromParent !== undefined
      ? navigate('/filter', {
          state: {
            srcroute: routerLocation.pathname,
            childpage: props.childPageFromParent,
            branchid: props.childPagePropsFromParent.branchid,
            storeid: props.childPagePropsFromParent.storeid,
            storename: props.childPagePropsFromParent.storename,
            expanded: props.childPagePropsFromParent.expanded,
            searchparam: props.childPagePropsFromParent.searchparam,
          },
        })
      : navigate('/filter', {
          state: {
            searchparam: searchParam,
          },
        });
  };

  /**
   * Search Icon Adornment
   * @returns
   */
  const endAdornment = () => {
    if (searchParam !== '') {
      return (
        <InputAdornment position='end'>
          <IconButton
            color='inherit'
            size='small'
            onClick={(e) => {
              searchParam.length >= 3
                ? setAnchorEl(e.currentTarget)
                : setSearchDialogVisible(true);
            }}
          >
            <AiOutlineSearch size='22' />
          </IconButton>
        </InputAdornment>
      );
    }
    return (
      <InputAdornment position='end' sx={{ paddingRight: 4, marginRight: 0 }} />
    );
  };

  // return
  return (
    <Container maxWidth={false} disableGutters>
      {/** Search Dialog */}
      <Dialog
        open={searchDialogVisible}
        onClose={() => setSearchDialogVisible(false)}
        aria-labelledby='search-dialog-title'
        aria-describedby='search-dialog-description'
      >
        <DialogTitle id='search-dialog-title' style={{ textAlign: 'center' }}>
          Suchbegriff fehlt!
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Mindestens 3 Zeichen eingeben!</DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => setSearchDialogVisible(false)}
            variant='outlined'
            color='inherit'
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/** Search Dialog end */}
      {/** AppBar */}
      <AppBar elevation={0}>
        <Toolbar disableGutters sx={{ pl: 2, pr: 2 }}>
          {/* Drawer Icons. Sichtbar bis sm 900px. */}
          <Box
            sx={{
              display: {
                xs: 'block',
                sm: 'block',
                md: 'block',
                lg: 'none',
                xl: 'none',
              },
            }}
          >
            {!drawerIsOpen ? (
              <IconButton color='inherit' onClick={() => setDrawerIsOpen(true)}>
                <AiOutlineMenu size='22' />
              </IconButton>
            ) : null}
          </Box>
          {/* Ende Drawer Icons */}
          <RouterLink to='/' style={{ paddingLeft: theme.spacing(1) }}>
            <img alt='merkantdo' src='/images/logos/logo-merkantdo.svg' />
          </RouterLink>
          {/*
            Menus Start, Geschäfte und Favoriten in der Topbar.
            Sichtbar ab md, 1200px.
          */}
          <Box
            sx={{
              ml: 8,
              display: {
                xs: 'none',
                sm: 'none',
                md: 'none',
                lg: 'block',
                xl: 'block',
              },
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Link
                component='button'
                color='textPrimary'
                onClick={() => navigate('/start')}
                style={{
                  textDecoration: 'none',
                }}
              >
                <Typography
                  variant='body1'
                  sx={{
                    mr: 4,
                    '&:hover, &:focus, &:active': {
                      textDecoration: `underline ${theme.palette.text.primary}`,
                    },
                  }}
                >
                  Start
                </Typography>
              </Link>
              <Link
                component='button'
                color='textPrimary'
                style={{
                  textDecoration: 'none',
                }}
                onClick={() =>
                  navigate('/stores', {
                    state: {
                      childpage: 'storesStart',
                      storeid: 0,
                    },
                  })
                }
              >
                <Typography
                  variant='body1'
                  sx={{
                    mr: 4,
                    '&:hover, &:focus, &:active': {
                      textDecoration: `underline ${theme.palette.text.primary}`,
                    },
                  }}
                >
                  Geschäfte
                </Typography>
              </Link>
              {!profile || profile.userrole === 'customer' ? (
                <Link
                  component='button'
                  color='textPrimary'
                  style={{
                    textDecoration: 'none',
                  }}
                  onClick={() => navigate('/favorite')}
                >
                  <Typography
                    variant='body1'
                    sx={{
                      color: theme.palette.text.primary,
                      '&:hover, &:focus, &:active': {
                        textDecoration: `underline ${theme.palette.text.primary}`,
                      },
                    }}
                  >
                    Favoriten
                  </Typography>
                </Link>
              ) : null}
            </Box>
          </Box>
          {/* Ende Menus Start, Geschäfte und Favoriten in der Topbar. */}
          <Box sx={{ flexGrow: 1 }} />
          {/** SearchBox der AppBar. Sichtbar ab md 900px. */}
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
                md: 'block',
                lg: 'block',
                xl: 'block',
              },
            }}
          >
            <FormControl>
              <TextField
                variant='outlined'
                autoComplete='off'
                type='text'
                sx={{
                  backgroundColor: theme.palette.background.default,
                  width: '240px',
                  border: 'none',
                  '& fieldset': { border: 'none' },
                }}
                size='small'
                placeholder='Suche'
                value={searchParam}
                onChange={(e) => {
                  handleSearchChange(e);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    checkSearchParam(e);
                  }
                }}
                InputProps={{
                  'aria-label': 'suche',
                  endAdornment: endAdornment(),
                }}
              />
              <div ref={anchorRef}>
                <Menu
                  sx={{
                    width: '271px',
                  }}
                  id='menuId'
                  autoFocus={false}
                  disableAutoFocus={true}
                  disableAutoFocusItem={true}
                  anchorEl={anchorRef.current}
                  open={menuOpen}
                  onClose={handleClose}
                  disableScrollLock={true}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {props.childPagePropsFromParent !== undefined &&
                  props.childPagePropsFromParent.storeid !== undefined &&
                  props.childPagePropsFromParent.storeid !== 0 ? (
                    <MenuItem
                      sx={{
                        width: '271px',
                      }}
                      onClick={() => {
                        props.sendChildPageToParent('storeSearch');
                        props.sendChildPagePropsToParent({
                          ...props.childPagePropsFromParent,
                          searchparam: searchParam,
                        });
                        handleClose();
                      }}
                    >
                      <ListItemText>In diesem Geschäft suchen</ListItemText>
                    </MenuItem>
                  ) : null}
                  <MenuItem
                    sx={{
                      width: '271px',
                    }}
                    onClick={() => {
                      setAnchorEl(null);
                      navigate('/search', {
                        state: {
                          searchparam: searchParam,
                        },
                      });
                    }}
                  >
                    <ListItemText>In allen Geschäften suchen</ListItemText>
                  </MenuItem>
                </Menu>
              </div>
            </FormControl>
            <IconButton
              sx={{
                marginLeft: theme.spacing(1),
                paddingTop: 0.9,
              }}
              color='inherit'
              size='small'
              onClick={() => {
                goToFilter();
              }}
            >
              <AiOutlineFilter size='22' />
            </IconButton>
          </Box>
          {/** Ende SearchBox der AppBar. */}
          {/** Icons rechts. Sichtbar ab lg, 1200px */}
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'none',
                lg: 'block',
                xl: 'block',
              },
            }}
          >
            <IconButton
              color='inherit'
              size='small'
              onClick={() => navigate('/location')}
            >
              <AiOutlineEnvironment size='22' />
            </IconButton>
            <ThemeContext.Consumer>
              {(themeContext) => (
                <IconButton
                  color='inherit'
                  size='small'
                  onClick={themeContext.toggleTheme}
                >
                  <BiAdjust size='22' />
                </IconButton>
              )}
            </ThemeContext.Consumer>
            <Badge
              color='secondary'
              badgeContent={notificationsNumber}
              overlap='circular'
              sx={{
                '& .MuiBadge-badge': {
                  fontSize: 9,
                  height: 15,
                  minWidth: 15,
                },
              }}
            >
              <IconButton color='inherit' size='small'>
                <AiOutlineInfoCircle size='22' />
              </IconButton>
            </Badge>
            {!isAuthenticated ? (
              <IconButton
                color='inherit'
                size='small'
                onClick={() => navigate('/login')}
              >
                <AiOutlineLogin size='22' />
              </IconButton>
            ) : (
              <>
                {profile.userrole === 'customer' ? (
                  <IconButton
                    color='inherit'
                    size='small'
                    onClick={() => navigate('/customer')}
                  >
                    <AiOutlineUser size='22' />
                  </IconButton>
                ) : (
                  <IconButton
                    color='inherit'
                    size='small'
                    onClick={() => navigate('/tenant')}
                  >
                    <AiOutlineUser size='22' />
                  </IconButton>
                )}
                <IconButton
                  color='inherit'
                  size='small'
                  onClick={() => {
                    doLogout();
                    navigate('/start');
                  }}
                >
                  <AiOutlineLogout size='22' />
                </IconButton>
              </>
            )}
          </Box>
          {/** Ende Icons rechts. */}
          {/** Search Icon bis sm, 600px */}
          <Box
            sx={{
              display: {
                xs: 'block',
                sm: 'none',
                md: 'none',
                lg: 'none',
                xl: 'none',
              },
            }}
          >
            <IconButton
              color='inherit'
              size='small'
              onClick={() => {
                setSearchBarIsOpen(true);
              }}
            >
              <AiOutlineSearch size='22' />
            </IconButton>
          </Box>
        </Toolbar>
        {/** End Search Icon bis sm, 600px */}
        {/* Suche Toolbar Sichtbar bis sm, 600px */}
        {searchBarIsOpen === true ? (
          <Box
            sx={{
              display: {
                xs: 'block',
                sm: 'none',
                md: 'none',
                lg: 'none',
                xl: 'none',
              },
            }}
          >
            <Divider />
            <Toolbar
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <FormControl>
                <TextField
                  variant='outlined'
                  autoComplete='off'
                  type='text'
                  sx={{
                    backgroundColor: theme.palette.background.default,
                    width: '240px',
                    border: 'none',
                    '& fieldset': { border: 'none' },
                  }}
                  size='small'
                  placeholder='Suche'
                  inputProps={{ 'aria-label': 'suche' }}
                  value={searchParam}
                  onChange={(e) => {
                    handleSearchChange(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      checkSearchParam(e);
                    }
                  }}
                  InputProps={{
                    'aria-label': 'suche',
                    endAdornment: endAdornment(),
                  }}
                />
                <div ref={anchorRef}>
                  <Menu
                    sx={{
                      width: '271px',
                    }}
                    id='menuId'
                    autoFocus={false}
                    disableAutoFocus={true}
                    disableAutoFocusItem={true}
                    anchorEl={anchorRef.current}
                    open={menuOpen}
                    onClose={handleClose}
                    disableScrollLock={true}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    {props.childPagePropsFromParent !== undefined &&
                    props.childPagePropsFromParent.storeid !== undefined &&
                    props.childPagePropsFromParent.storeid !== 0 ? (
                      <MenuItem
                        sx={{
                          width: '271px',
                        }}
                        onClick={() => {
                          props.sendChildPageToParent('storeSearch');
                          props.sendChildPagePropsToParent({
                            ...props.childPagePropsFromParent,
                            searchparam: searchParam,
                          });
                          handleClose();
                        }}
                      >
                        <ListItemText>In diesem Geschäft suchen</ListItemText>
                      </MenuItem>
                    ) : null}
                    <MenuItem
                      sx={{
                        width: '271px',
                      }}
                      onClick={() => {
                        setAnchorEl(null);
                        navigate('/search', {
                          state: { searchparam: searchParam },
                        });
                      }}
                    >
                      <ListItemText>In allen Geschäften suchen</ListItemText>
                    </MenuItem>
                  </Menu>
                </div>
              </FormControl>
              <IconButton
                sx={{
                  paddingLeft: theme.spacing(1),
                }}
                color='inherit'
                size='small'
                onClick={() => {
                  routerLocation.state === null &&
                  props.childPagePropsFromParent !== undefined
                    ? navigate('/filter', {
                        state: {
                          srcroute: routerLocation.pathname,
                          storeid: props.childPagePropsFromParent.storeid,
                          searchparam: searchParam,
                        },
                      })
                    : navigate('/filter', {
                        state: {
                          srcroute: routerLocation.pathname,
                          searchparam: searchParam,
                        },
                      });
                }}
              >
                <AiOutlineFilter size='22' />
              </IconButton>
            </Toolbar>
          </Box>
        ) : null}
        {/* Ende SearchBar. */}
      </AppBar>
      {/** Ende AppBar */}
      {/** Drawer */}
      <Box
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'block',
            lg: 'none',
            xl: 'none',
          },
        }}
      >
        <Drawer variant='persistent' open={drawerIsOpen}>
          <Box
            pr={1}
            sx={{
              height: '56px',
              width: '240px',
              [theme.breakpoints.up('sm')]: {
                height: '64px',
                width: '260px',
              },
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {drawerIsOpen ? (
              <IconButton
                color='inherit'
                onClick={() => setDrawerIsOpen(false)}
              >
                <AiOutlineClose size='22' />
              </IconButton>
            ) : null}
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              height: 38,
            }}
          >
            <IconButton
              color='inherit'
              size='small'
              onClick={() => {
                setDrawerIsOpen(false);
                navigate('/location');
              }}
            >
              <AiOutlineEnvironment size='22' />
            </IconButton>
            <ThemeContext.Consumer>
              {(themeContext) => (
                <IconButton
                  color='inherit'
                  size='small'
                  onClick={() => {
                    themeContext.toggleTheme();
                    setDrawerIsOpen(false);
                  }}
                >
                  <BiAdjust size='22' />
                </IconButton>
              )}
            </ThemeContext.Consumer>
            <Badge
              color='secondary'
              badgeContent={notificationsNumber}
              overlap='circular'
              sx={{
                '& .MuiBadge-badge': {
                  fontSize: 9,
                  height: 15,
                  minWidth: 15,
                },
              }}
            >
              <IconButton color='inherit' size='small'>
                <AiOutlineInfoCircle size='22' />
              </IconButton>
            </Badge>
            {!isAuthenticated ? (
              <IconButton
                color='inherit'
                size='small'
                onClick={() => {
                  setDrawerIsOpen(false);
                  navigate('/login');
                }}
              >
                <AiOutlineLogin size='22' />
              </IconButton>
            ) : (
              <>
                {profile.userrole === 'customer' ? (
                  <>
                    <IconButton
                      color='inherit'
                      size='small'
                      onClick={() => {
                        setDrawerIsOpen(false);
                        navigate('/customer');
                      }}
                    >
                      <AiOutlineUser size='22' />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    color='inherit'
                    size='small'
                    onClick={() => {
                      setDrawerIsOpen(false);
                      navigate('/tenant');
                    }}
                  >
                    <AiOutlineUser size='22' />
                  </IconButton>
                )}
                <IconButton
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setDrawerIsOpen(false);
                    doLogout();
                    navigate('/start');
                  }}
                >
                  <AiOutlineLogout size='22' />
                </IconButton>
              </>
            )}
          </Box>
          <Divider />
          <>
            <List sx={{ paddingTop: 0 }}>
              <ListItemButton
                sx={{ height: 32 }}
                onClick={() => {
                  navigate('/start');
                  setDrawerIsOpen(false);
                }}
              >
                <ListItemText sx={{ ml: 0 }}>Start</ListItemText>
              </ListItemButton>
              <ListItemButton
                sx={{ height: 32 }}
                onClick={() => {
                  navigate('/stores', {
                    state: {
                      childpage: 'storesStart',
                      storeid: 0,
                      drawerisopen: true,
                    },
                  });
                }}
              >
                <ListItemText sx={{ ml: 0 }}>Geschäfte</ListItemText>
              </ListItemButton>
              {routerLocation.pathname === '/stores'
                ? branchesData.merkantdo_locationbranches.map((branch: any) => (
                    <React.Fragment key={branch.id}>
                      <ListItemButton
                        sx={{ height: 32, pl: 4 }}
                        onClick={() => {
                          props.sendChildPageToParent('storesStart');
                          props.sendChildPagePropsToParent({
                            ...props.childPagePropsFromParent,
                            branchid: branch.id,
                            branchname: branch.branchname,
                            storeid: 0,
                            actiontype: 'all',
                            pagenumber: 1,
                            expanded: { [branch.id]: true },
                          });
                          setDrawerIsOpen(true);
                        }}
                      >
                        {props.childPagePropsFromParent.expanded[branch.id] ? (
                          <ListItemText>{branch.branchname}</ListItemText>
                        ) : (
                          <ListItemText>{branch.branchname}</ListItemText>
                        )}
                      </ListItemButton>
                      <Collapse
                        in={props.childPagePropsFromParent.expanded[branch.id]}
                        timeout='auto'
                        unmountOnExit
                      >
                        {storesData !== undefined ? (
                          <List component='div' disablePadding dense>
                            {storesData.merkantdo_branchstores.map(
                              (store: any) => (
                                <ListItemButton
                                  sx={{ height: 32, pl: 6 }}
                                  key={store.id}
                                  onClick={() => {
                                    props.sendChildPageToParent('store');
                                    props.sendChildPagePropsToParent({
                                      ...props.childPagePropsFromParent,
                                      storeid: store.id,
                                      storename: store.storename,
                                      categoryid: null,
                                    });
                                    setDrawerIsOpen(false);
                                  }}
                                >
                                  {store.id ===
                                  props.childPagePropsFromParent.storeid ? (
                                    <ListItemText>
                                      {store.storename}
                                    </ListItemText>
                                  ) : (
                                    <ListItemText>
                                      {store.storename}
                                    </ListItemText>
                                  )}
                                </ListItemButton>
                              )
                            )}
                          </List>
                        ) : (
                          <Typography variant='caption'>
                            Keine vorhanden!
                          </Typography>
                        )}
                      </Collapse>
                    </React.Fragment>
                  ))
                : null}
              {!profile || profile.userrole === 'customer' ? (
                <ListItemButton
                  sx={{ height: 32 }}
                  onClick={() => {
                    navigate('/favorite');
                    setDrawerIsOpen(false);
                  }}
                >
                  <ListItemText sx={{ ml: 0 }}>Favoriten</ListItemText>
                </ListItemButton>
              ) : null}
            </List>
          </>
        </Drawer>
      </Box>
      {/** Ende Drawer */}
    </Container>
  );
};

export default Topbar;
