import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import {
  Container,
  Box,
  FormControl,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  useTheme,
} from '@mui/material';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { ADD_ACCOUNT, GET_ACCOUNT } from '../data/AccountData';
import { ADD_CUSTOMER } from '../customer/queries/customerQueries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IInputError } from '../interfaces/interfaces';
import axios from 'axios';
import { Config } from '../config';
import CryptoJS from 'crypto-js';

/**
 * RegisterCustomerView um sich am System
 * mit Email und Password als Kunde zu registrieren.
 */
const RegisterCustomerView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [confirmedPassword, setConfirmedPassword] = useState<string>('');
  const [confirmedPasswordError, setConfirmedPasswordError] = useState<
    Partial<IInputError>
  >({
    isError: false,
    message: '',
  });
  const passwordSecret = 'uk72tab';
  const [isButtonRegisterEnabled, setIsButtonRegisterEnabled] =
    useState<boolean>(false);
  const [isPasswordSecure, setIsPasswordSecure] = useState<boolean>(true);
  const NODEMAILER_URL: any =
    process.env.REACT_APP_NODEMAILER_URL !== undefined
      ? process.env.REACT_APP_NODEMAILER_URL
      : Config.nodemailer_url;

  // account überprüfen ob vonhanden ist
  const [checkAccount] = useLazyQuery(GET_ACCOUNT);

  // account hinzufügen
  const [addAccount] = useMutation(ADD_ACCOUNT);

  // customer hinzufügen
  const [addCustomer] = useMutation(ADD_CUSTOMER);

  /**
   * Handling Register Button Status
   */
  useEffect(() => {
    if (
      email === '' ||
      password === '' ||
      password !== confirmedPassword ||
      emailError.isError === true ||
      passwordError.isError === true ||
      confirmedPasswordError.isError === true
    ) {
      setIsButtonRegisterEnabled(false);
    } else {
      setIsButtonRegisterEnabled(true);
    }
  }, [
    email,
    password,
    confirmedPassword,
    emailError.isError,
    passwordError.isError,
    confirmedPasswordError.isError,
  ]);

  /**
   * Funktion zum Umschalten des Password-Inputs,
   * sichtbar oder nicht sichtbar.
   */
  const handlePasswordSecure = () => {
    setIsPasswordSecure(!isPasswordSecure);
  };

  /**
   * Email error wird zurückgesetzt.
   */
  const handleEmailOnFocus = () => {
    setEmailError({
      isError: false,
      message: '',
    });
  };

  /**
   * Email im State setzen.
   */
  const handleEmailInput = (e: any) => {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setEmail(e.target.value);
    if (regexp.test(e.target.value)) {
      setEmailError({
        isError: false,
        message: '',
      });
    } else {
      setEmailError({
        isError: true,
        message: '',
      });
    }
  };

  /**
   * Funktion zur Validierung der Email.
   */
  const checkEmailInput = async () => {
    // eslint-disable-next-line no-useless-escape
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === '') {
      // email ist leer
      setEmailError({
        isError: true,
        message: 'E-Mail-Adresse ist erforderlich!',
      });
    } else if (regexp.test(email)) {
      // email ist ok
      // überprüfen ob die email schon vorhanden ist
      try {
        const { data } = await checkAccount({
          variables: { email },
          fetchPolicy: 'network-only',
        });
        if (data.account.length === 0) {
          // email ist ok, und nicht vorhanden
          setEmailError({
            isError: false,
            message: '',
          });
        } else {
          // email ist ok, aber schon vorhanden
          setEmailError({
            isError: true,
            message: 'Diese E-Mail-Adresse ist schon vorhanden.',
          });
        }
      } catch (error) {
        console.log('Error! RegisterCustomerView - checkEmailInput:', error);
      }
    } else {
      // email ist nicht leer, aber nicht ok
      setEmailError({
        isError: true,
        message: 'E-Mail-Adresse ist nicht korrect.',
      });
    }
  };

  /**
   * Password error wird zurückgesetzt.
   */
  const handlePasswordOnFocus = () => {
    if (email === '') {
      setEmailError({
        isError: true,
        message: 'E-Mail-Adresse ist erforderlich!',
      });
    }
    setPasswordError({ isError: false, message: '' });
  };

  /**
   * Password wird im State gesetzt.
   */
  const handlePasswordInput = (e: any) => {
    const regexp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,20}$/;
    setPassword(e.target.value);
    if (regexp.test(e.target.value)) {
      setPasswordError({
        isError: false,
        message: '',
      });
    } else {
      setPasswordError({
        isError: true,
        message: '',
      });
    }
  };

  /**
   * Funktion zur Validierung des Passwords.
   * Mindestens 6 Stellen, höchstens 20 Stellen,
   * mindesten ein Großbuchstabe, mindestens ein Kleinbuchstabe
   * und mindestens eine Ziffer.
   */
  const checkPasswordInput = () => {
    const regexp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,20}$/;
    if (password === '') {
      setPasswordError({
        isError: true,
        message: 'Passwort ist erforderlich!',
      });
    } else if (regexp.test(password)) {
      setPasswordError({
        isError: false,
        message: '',
      });
    } else {
      setPasswordError({
        isError: true,
        message:
          'Muss zwischen 6 und 20 Zeichen sein, mindestens 1 Großbuchstaben, 1 Kleinbuchstaben und 1 Ziffer beinhalten!',
      });
    }
  };

  /**
   * Confirmed Password error wird zurückgesetzt.
   */
  const handleConfirmedPasswordOnFocus = () => {
    if (email === '') {
      setEmailError({
        isError: true,
        message: 'E-Mail-Adresse ist erforderlich!',
      });
    }
    setConfirmedPasswordError({
      isError: false,
      message: '',
    });
  };

  /**
   * Confirmed Password wird im State gesetzt.
   */
  const handleConfirmedPasswordInput = (e: any) => {
    setConfirmedPassword(e.target.value);
    if (e.target.value.length < 6) {
      setConfirmedPasswordError({
        isError: true,
        message: 'Passwörter müssen zwischen 6 und 20 Zeichen lang sein!',
      });
    } else if (e.target.value.length >= 6 && password !== e.target.value) {
      setConfirmedPasswordError({
        isError: true,
        message: 'Passwörter sind nicht gleich!',
      });
    } else {
      setConfirmedPasswordError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Diese Funktion überprüft ob die beiden
   * Einträge für das Passwort gleich sind.
   */
  const checkConfirmedPasswordInput = () => {
    if (confirmedPassword === '') {
      setConfirmedPasswordError({
        isError: true,
        message: 'Password wiederholen!',
      });
    } else if (password !== confirmedPassword) {
      setConfirmedPasswordError({
        isError: true,
        message: 'Passwörter sind nicht gleich!',
      });
    }
  };

  /**
   * Diese Funktion erstellt einen Account und ruf
   * eine Funktion zum Erstellen eines Customers.
   */
  const registerCustomer = async () => {
    // addAccount
    try {
      const { data: accountData } = await addAccount({
        variables: {
          account: {
            id: undefined,
            email,
            password: CryptoJS.AES.encrypt(password, passwordSecret).toString(),
            userrole: 'customer',
          },
        },
      });
      // addCustomer
      try {
        const { data: customerData } = await addCustomer({
          variables: {
            customer: {
              id: undefined,
              account_id: accountData.insert_account_one.id,
            },
          },
        });
        if (customerData.insert_customer_one.id !== null) {
          try {
            const response = await axios({
              baseURL: NODEMAILER_URL,
              method: 'POST',
              url: '/register',
              data: {
                email,
                subject: 'Regisrierung',
              },
            });
            if (response.data.status === 'success') {
              navigate('/login');
            }
          } catch (error) {
            console.log('Error! Unable to send Email: ', error);
          }
        }
      } catch (error) {
        console.log('Error! RegisterCustomerView - addCustomer:', error);
      }
    } catch (error) {
      console.log('Error! RegisterCustomerView - addAccount:', error);
    }
  };

  // return
  return (
    <Container maxWidth='xs'>
      <Box mt={3}>
        <Header
          title='Kundenregistrierung'
          subtitle='Geben Sie unten Ihre E-Mail-Adresse und Passwort ein, um sich zu
          registrieren. Registrierte Kunden haben die Möglichkeit Favoriten
          anzulegen, über neue Angebote von Ihren Favoriten informiert zu werden
          und Fragen an ein Geschäft zu stellen.'
        />
      </Box>
      <Box>
        <FormControl required sx={{ width: '100%' }}>
          <TextField
            id='email'
            name='email'
            inputProps={{
              sx: {
                '&:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset`,
                  WebkitTextFillColor: theme.palette.text.primary,
                },
              },
            }}
            variant='standard'
            color='secondary'
            margin='dense'
            required
            label='E-Mail-Adresse'
            value={email}
            autoComplete='email'
            error={emailError.isError}
            helperText={emailError.message}
            FormHelperTextProps={{
              sx: { color: theme.palette.secondary.main },
            }}
            onFocus={handleEmailOnFocus}
            onChange={(e) => handleEmailInput(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                checkEmailInput();
              }
            }}
            onBlur={checkEmailInput}
          />
          <TextField
            id='password'
            name='password'
            variant='standard'
            color='secondary'
            margin='dense'
            required
            label='Password'
            value={password}
            type={isPasswordSecure ? 'password' : 'text'}
            error={passwordError.isError}
            helperText={passwordError.message}
            FormHelperTextProps={{
              sx: { color: theme.palette.secondary.main },
            }}
            onFocus={handlePasswordOnFocus}
            onChange={(e) => handlePasswordInput(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                checkPasswordInput();
              }
            }}
            onBlur={checkPasswordInput}
            InputProps={{
              endAdornment: (
                // eslint-disable-next-line react/jsx-indent
                <InputAdornment position='end'>
                  <IconButton color='inherit' onClick={handlePasswordSecure}>
                    {isPasswordSecure ? (
                      <AiOutlineEye size='22' />
                    ) : (
                      <AiOutlineEyeInvisible size='22' />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id='confirmedPassword'
            name='confirmedPassword'
            variant='standard'
            color='secondary'
            margin='dense'
            required
            label='Password wiederholen'
            value={confirmedPassword}
            type={isPasswordSecure ? 'password' : 'text'}
            error={confirmedPasswordError.isError}
            helperText={confirmedPasswordError.message}
            FormHelperTextProps={{
              sx: { color: theme.palette.secondary.main },
            }}
            onFocus={handleConfirmedPasswordOnFocus}
            onChange={(e) => handleConfirmedPasswordInput(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                checkConfirmedPasswordInput();
              }
            }}
            onBlur={checkConfirmedPasswordInput}
            InputProps={{
              endAdornment: (
                // eslint-disable-next-line react/jsx-indent
                <InputAdornment position='end'>
                  <IconButton color='inherit' onClick={handlePasswordSecure}>
                    {isPasswordSecure ? (
                      <AiOutlineEye size='22' />
                    ) : (
                      <AiOutlineEyeInvisible size='22' />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Box>
      <Box
        mt={3}
        mb={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            mt: 1,
            borderRadius: 30,
            width: '240px',
          }}
          type='submit'
          disabled={!isButtonRegisterEnabled}
          variant='contained'
          color='secondary'
          onClick={() => registerCustomer()}
        >
          Registrieren
        </Button>
        <Button
          sx={{
            mt: 2,
            borderRadius: 30,
            width: '240px',
          }}
          type='submit'
          variant='contained'
          color='secondary'
          onClick={() => navigate('/login')}
        >
          Abbrechen
        </Button>
      </Box>
    </Container>
  );
};

export default RegisterCustomerView;
