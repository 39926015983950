import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
} from '@mui/material';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import {
  useNavigate,
  useLocation as useRouterLocation,
  Link as RouterLink,
} from 'react-router-dom';
import { useAuth } from '../authContext';
import {
  LOGIN_BY_CREDENTIALS,
  GET_CUSTOMER_PROFILE,
  GET_TENANT_PROFILE,
  GET_PORTALADMIN_PROFILE,
} from './queries/loginQueries';
import { GET_ACCOUNT } from '../data/AccountData';
import { useLazyQuery } from '@apollo/client';
import { IInputError } from '../interfaces/interfaces';
import Header from '../components/Header';
import CryptoJS from 'crypto-js';

/**
 * LoginView um sich am System
 * mit Email und Password anzumelden.
 */
const LoginView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const routerLocation: any = useRouterLocation();
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useAuth();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const passwordSecret = 'uk72tab';
  const [passwordCrypt, setPasswordCrypt] = useState<string>('');

  const [isButtonLoginEnabled, setIsButtonLoginEnabled] =
    useState<boolean>(false);
  const [notFavoriteDialogVisible, setNotFavoriteDialogVisible] =
    useState<boolean>(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>('');
  const [isPasswordSecure, setIsPasswordSecure] = useState<boolean>(true);
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  // get customer profile
  const [getCustomerProfile] = useLazyQuery(GET_CUSTOMER_PROFILE);

  // get tenant profile
  const [getTenantProfile] = useLazyQuery(GET_TENANT_PROFILE);

  // get portaladmin profile
  const [getPortaladminProfile] = useLazyQuery(GET_PORTALADMIN_PROFILE);

  // account überprüfen ob vonhanden ist
  const [checkAccount] = useLazyQuery(GET_ACCOUNT);

  // login
  const [login] = useLazyQuery(LOGIN_BY_CREDENTIALS);

  /**
   * Email und Password aus dem localStorage holen
   * und im State setzen.
   */
  useEffect(() => {
    //localStorage.clear();
    const tempRememberMe: any = localStorage.getItem('rememberMe');
    const tempEmail: any = localStorage.getItem('email');
    const tempPassword: any = localStorage.getItem('password');
    setRememberMe(JSON.parse(tempRememberMe));
    if (JSON.parse(tempRememberMe) === true) {
      setEmail(tempEmail);
      setPasswordCrypt(tempPassword);
      const bytes = CryptoJS.AES.decrypt(tempPassword, passwordSecret);
      setPassword(bytes.toString(CryptoJS.enc.Utf8));
    } else {
      setEmail('');
      setPassword('');
      setPasswordCrypt('');
    }
  }, []);

  /**
   * RememberMe steuern
   */
  const handleRememberMe = (status: boolean) => {
    localStorage.setItem('rememberMe', JSON.stringify(status));
    setRememberMe(status);
    if (status === true) {
      localStorage.setItem('email', email);
      localStorage.setItem('password', passwordCrypt);
    } else {
      localStorage.removeItem('email');
      localStorage.removeItem('password');
    }
  };

  /**
   * Button Login Status setzen.
   */
  useEffect(() => {
    if (
      email === '' ||
      password === '' ||
      emailError.isError === true ||
      passwordError.isError === true
    ) {
      setIsButtonLoginEnabled(false);
    } else {
      if (rememberMe === true) {
        localStorage.setItem('email', email);
        localStorage.setItem('password', passwordCrypt);
      }
      setIsButtonLoginEnabled(true);
    }
  }, [
    email,
    password,
    emailError.isError,
    passwordError.isError,
    rememberMe,
    passwordCrypt,
  ]);

  /**
   * Funktion zum Umschalten des Password-Inputs,
   * sichtbar oder nicht sichtbar.
   */
  const handlePasswordSecure = () => {
    setIsPasswordSecure(!isPasswordSecure);
  };

  /**
   * Email error wird zurückgesetzt.
   
  const handleEmailOnFocus = () => {
    setEmailError({
      isError: false,
      message: '',
    });
  };
  */

  /**
   * Set Email
   */
  const handleEmailInput = (e: any) => {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setEmail(e.target.value);
    if (regexp.test(e.target.value)) {
      setEmailError({
        isError: false,
        message: '',
      });
    } else {
      setEmailError({
        isError: true,
        message: '',
      });
    }
  };

  /**
   * Funktion zur Validierung der Email.
   */
  const checkEmailInput = async () => {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === '') {
      setEmailError({
        isError: true,
        message: 'E-Mail-Adresse ist erforderlich!',
      });
    } else if (regexp.test(email)) {
      // this is a valid email address
      setEmailError({
        isError: false,
        message: '',
      });
      setLoginErrorMessage('');
      try {
        const { data } = await checkAccount({
          variables: { email },
          fetchPolicy: 'network-only',
        });
        if (data.account.length === 0) {
          setEmailError({
            isError: true,
            message: '',
          });
          setLoginErrorMessage('E-Mail-Adresse ist nicht vorhanden!');
        } else {
          setPasswordCrypt(data.account[0].password);
        }
      } catch (error) {
        console.log('Error! LoginView - checkEmailInput:', error);
      }
    } else {
      // invalid email, maybe show an error to the user.
      setEmailError({
        isError: true,
        message: 'Ist keine gültige E-Mail-Adresse.',
      });
    }
  };

  /**
   * Password error wird zurückgesetzt.
   */
  const handlePasswordOnFocus = () => {
    checkEmailInput();
    if (email === '') {
      setEmailError({
        isError: true,
        message: 'E-Mail-Adresse ist erforderlich!',
      });
    }
    setPasswordError({ isError: false, message: '' });
  };

  /**
   * Password wird gesetzt.
   */
  const handlePasswordInput = (e: any) => {
    const regexp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,20}$/;
    setPassword(e.target.value);
    if (regexp.test(e.target.value)) {
      setPassword(e.target.value);
      setPasswordError({
        isError: false,
        message: '',
      });
    } else {
      setPasswordError({
        isError: true,
        message: '',
      });
    }
  };

  /**
   * Funktion zur Validierung der Password.
   */
  const checkPasswordInput = () => {
    if (password === '') {
      setPasswordError({
        isError: true,
        message: 'Passwort ist erforderlich!',
      });
    } else if (emailError.isError === false && password !== '') {
      setPasswordError({
        isError: false,
        message: '',
      });
      setLoginErrorMessage('');
      setIsButtonLoginEnabled(true);
    } else {
      setIsButtonLoginEnabled(false);
    }
  };

  /**
   * Liefert das Profil der Person un abhängig von der userrole
   * setzt das global im authContext.
   */
  const getProfile = async (accountId: number, userrole: string) => {
    if (userrole === 'customer') {
      try {
        const { data } = await getCustomerProfile({
          variables: { account_id: accountId },
          fetchPolicy: 'network-only',
        });
        await dispatch({
          type: 'LOGIN',
          payload: {
            account_id: accountId,
            userrole,
            customer_id: data.customer[0].id,
            email,
            password: passwordCrypt,
          },
        });
        if (routerLocation.state !== null) {
          navigate(routerLocation.state.srcroute, {
            state: {
              srcroute: routerLocation.state.parentsrcroute,
              childpage: routerLocation.state.childpage,
              branchid: routerLocation.state.branchid,
              expanded: routerLocation.state.expanded,
              storeid: routerLocation.state.storeid,
              categoryid: routerLocation.state.categoryid,
              productid: routerLocation.state.productid,
              actiontype: routerLocation.state.actiontype,
              searchparam: routerLocation.state.searchparam,
              action: routerLocation.state.action,
              pagenumber: routerLocation.state.pagenumber,
            },
          });
        } else {
          navigate('/customer');
        }
      } catch (error) {
        console.log('Error! LoginView - getCustomerProfile:', error);
      }
    } else if (userrole === 'tenant') {
      if (
        routerLocation.state !== null &&
        routerLocation.state.srcroute === '/favorite'
      ) {
        setNotFavoriteDialogVisible(true);
      } else {
        try {
          const { data } = await getTenantProfile({
            variables: { account_id: accountId },
            fetchPolicy: 'network-only',
          });
          await dispatch({
            type: 'LOGIN',
            payload: {
              account_id: accountId,
              userrole,
              tenant_id: data.tenant[0].id,
              email,
              password: passwordCrypt,
            },
          });
          if (routerLocation.state !== null) {
            navigate(routerLocation.state.srcroute, {
              state: {
                srcroute: routerLocation.state.parentsrcroute,
                childpage: routerLocation.state.childpage,
                branchid: routerLocation.state.branchid,
                expanded: routerLocation.state.expanded,
                storeid: routerLocation.state.storeid,
                categoryid: routerLocation.state.categoryid,
                productid: routerLocation.state.productid,
                actiontype: routerLocation.state.actiontype,
                pagenumber: routerLocation.state.pagenumber,
                action:
                  routerLocation.state.action === 'messageSend'
                    ? 'messageNotSend'
                    : routerLocation.state.action === 'favoriteInsert'
                    ? 'favoriteNotInsert'
                    : undefined,
              },
            });
          } else {
            navigate('/tenant');
          }
        } catch (error) {
          console.log('Error! LoginView - getTenantProfile:', error);
        }
      }
    } else if (userrole === 'portaladmin') {
      try {
        const { data } = await getPortaladminProfile({
          variables: { account_id: accountId },
          fetchPolicy: 'network-only',
        });
        await dispatch({
          type: 'LOGIN',
          payload: {
            account_id: accountId,
            userrole,
            portaladmin_id: data.portaladmin[0].id,
            email,
            password: passwordCrypt,
          },
        });
        if (routerLocation.state !== null) {
          navigate(routerLocation.state.srcroute);
        } else {
          navigate('/admin');
        }
      } catch (error) {
        console.log('Error! LoginView - getPortaladminProfile:', error);
      }
    }
  };

  /**
   * Loginfunktion des Users
   * mit email und password anzumelden.
   */
  const doLogin = async () => {
    try {
      const { data } = await login({
        variables: { email },
        fetchPolicy: 'network-only',
      });
      const bytes = CryptoJS.AES.decrypt(
        data.account[0].password,
        passwordSecret
      );
      if (password !== bytes.toString(CryptoJS.enc.Utf8)) {
        setLoginErrorMessage('E-Mail-Adresse oder Passwort falsch!');
      } else {
        await getProfile(data.account[0].id, data.account[0].userrole);
      }
    } catch (error) {
      console.log('Error! LoginView - doLogin:', error);
    }
  };

  // return
  return (
    <Container maxWidth='xs'>
      <Dialog
        open={notFavoriteDialogVisible}
        onClose={() => setNotFavoriteDialogVisible(false)}
        aria-labelledby='not-favorite-dialog-title'
        aria-describedby='not-favorite-dialog-description'
      >
        <DialogTitle id='not-favorite-dialog-title'>
          Favoriten nicht verfügbar!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='not-favorite-dialog-description'>
            Favoriten sind für Geschäftskonten nicht verfügbar.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => navigate('/start')}
            variant='outlined'
            color='inherit'
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Box mt={3} mb={2}>
        <Header
          title='Login'
          subtitle='Geben Sie unten Ihre E-Mail-Adresse und Passwort ein, um sich bei
          Ihrem Konto anzumelden.'
        />
      </Box>
      <Box>
        <Typography
          variant='subtitle1'
          align='center'
          sx={{ color: theme.palette.secondary.main }}
        >
          {loginErrorMessage}
        </Typography>
        <FormControl required sx={{ width: '100%' }}>
          <TextField
            id='email'
            name='email'
            sx={{ m: theme.spacing(1, 0, 1) }}
            inputProps={{
              sx: {
                '&:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset`,
                  WebkitTextFillColor: theme.palette.text.primary,
                },
              },
            }}
            variant='standard'
            color='secondary'
            margin='dense'
            required
            label='E-Mail-Adresse'
            value={email}
            autoComplete='email'
            error={emailError.isError}
            helperText={emailError.message}
            FormHelperTextProps={{
              sx: { color: theme.palette.secondary.main },
            }}
            //onFocus={handleEmailOnFocus}
            onChange={(e) => handleEmailInput(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                checkEmailInput();
              }
            }}
            onBlur={checkEmailInput}
          />
          <TextField
            id='password'
            name='password'
            variant='standard'
            color='secondary'
            margin='dense'
            required
            label='Password'
            value={password}
            type={isPasswordSecure ? 'password' : 'text'}
            error={passwordError.isError}
            helperText={passwordError.message}
            FormHelperTextProps={{
              sx: { color: theme.palette.secondary.main },
            }}
            onFocus={handlePasswordOnFocus}
            onChange={(e) => handlePasswordInput(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                checkPasswordInput();
              }
            }}
            onBlur={checkPasswordInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton color='inherit' onClick={handlePasswordSecure}>
                    {isPasswordSecure ? (
                      <AiOutlineEye size='22' />
                    ) : (
                      <AiOutlineEyeInvisible size='22' />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Box>
      <Box mt={2} mb={2}>
        <FormControlLabel
          control={
            <Checkbox
              value={rememberMe}
              checked={rememberMe}
              onChange={() => handleRememberMe(!rememberMe)}
              color='secondary'
              disabled={!isButtonLoginEnabled}
            />
          }
          label='Logindaten speichern?'
        />
        <Box
          mt={2}
          mb={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Button
            type='submit'
            sx={{
              borderRadius: 30,
              width: '240px',
              alignSelf: 'center',
            }}
            disabled={!isButtonLoginEnabled}
            variant='contained'
            color='secondary'
            onClick={() => doLogin()}
          >
            Login
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <RouterLink
              style={{
                textDecoration: 'none',
              }}
              to='/forgotPassword'
            >
              <Typography
                variant='body1'
                sx={{
                  color: theme.palette.text.primary,
                  '&:hover, &:focus, &:active': {
                    textDecoration: `underline ${theme.palette.text.primary}`,
                  },
                }}
              >
                Passwort vergessen?
              </Typography>
            </RouterLink>
          </Box>
          <Box>
            <RouterLink
              style={{
                textDecoration: 'none',
              }}
              to='/registerCustomer'
            >
              <Typography
                variant='body1'
                sx={{
                  color: theme.palette.text.primary,
                  '&:hover, &:focus, &:active': {
                    textDecoration: `underline ${theme.palette.text.primary}`,
                  },
                }}
              >
                Noch kein Konto? Registrieren!
              </Typography>
            </RouterLink>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginView;
