import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  useTheme,
  Autocomplete,
} from '@mui/material';
import Header2 from '../components/Header2';
import {
  AiOutlineEdit,
  AiOutlineQuestionCircle,
  AiOutlineClose,
} from 'react-icons/ai';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { GET_STORE_BY_TENANT_ID } from '../data/StoreData';
import { GET_STORE_BRANCHES, GET_ALL_BRANCHES } from '../data/BranchesData';
import {
  ADD_STOREBRANCHES,
  REMOVE_STOREBRANCH_BY_STORE,
  UPDATE_STORE,
  GET_LOCATION_BY_ZIPCODE,
} from './queries/tenantQueries';
import { IInputError } from '../interfaces/interfaces';
import { IStoreBranch } from '../interfaces/interfaces';
import { useAuth } from '../authContext';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import TenantStoreHelpView from './help/TenantStoreHelpView';

/**
 * TenantStoreView stellt die Daten
 * eines Stores dar.
 */
const TenantStoreView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [{ profile }] = useAuth();
  const [storeId, setStoreId] = useState<number>(0);
  const [storeLocationId, setStoreLocationId] = useState<number>(0);
  const [storeName, setStoreName] = useState<string>('');
  const [storeNameError, setStoreNameError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [storeOwner, setStoreOwner] = useState<string>('');
  const [storeOwnerError, setStoreOwnerError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [zipcode, setZipcode] = useState<string>('');
  const [zipcodeError, setZipcodeError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [place, setPlace] = useState<string>('');
  const [street, setStreet] = useState<string>('');
  const [streetError, setStreetError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [phone, setPhone] = useState<string>('');
  const [phoneError, setPhoneError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [isButtonUpdateEnabled, setIsButtonUpdateEnabled] =
    useState<boolean>(false);
  const [zipcodeDropdownList, setZipcodeDropdownList] = useState<any>([]);
  const [zipcodeDropdownListIsSet, setZipcodeDropdownListIsSet] =
    useState<boolean>(false);
  const [isStatusEdit, setIsStatusEdit] = useState<boolean>(false);
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [allBranches, setAllBranches] = useState<Array<IStoreBranch>>([]);
  const [branchesDialogVisible, setBranchesDialogVisible] =
    useState<boolean>(false);
  const routerLocation: any = useRouterLocation();
  const [helpOpen, setHelpOpen] = useState<boolean>(
    routerLocation.state.helpopen
  );

  // get store by tenantId
  const {
    loading: storeLoading,
    error: storeError,
    //data: storeData,
  } = useQuery(GET_STORE_BY_TENANT_ID, {
    variables: { tenantid: profile.tenant_id },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setStoreId(data.store[0].id);
      setStoreLocationId(data.store[0].location_id);
      setStoreName(data.store[0].storename);
      setStoreOwner(data.store[0].storeowner);
      setZipcode(data.store[0].location.zipcode);
      setPlace(data.store[0].location.place);
      setStreet(data.store[0].street);
      setPhone(data.store[0].phone);
    },
  });

  // update store
  const [updateStore] = useMutation(UPDATE_STORE, {
    refetchQueries: [
      {
        query: GET_STORE_BY_TENANT_ID,
        variables: { tenantid: profile.tenant_id },
      },
    ],
  });

  // get locations
  const [getLocations] = useLazyQuery(GET_LOCATION_BY_ZIPCODE);

  // get storebranches
  const {
    loading: storeBranchesLoading,
    error: storeBranchesError,
    data: storeBranchesData,
  } = useQuery(GET_STORE_BRANCHES, {
    skip: storeId === 0,
    variables: { storeid: storeId },
  });

  // get all branches
  const {
    loading: allBranchesLoading,
    error: allBranchesError,
    data: allBranchesData,
  } = useQuery(GET_ALL_BRANCHES);

  // add store branches
  const [addStorebranches] = useMutation(ADD_STOREBRANCHES, {
    refetchQueries: [
      {
        query: GET_STORE_BRANCHES,
        variables: { storeid: storeId },
        fetchPolicy: 'network-only',
      },
    ],
  });

  // remove store branch
  const [removeStorebranch] = useMutation(REMOVE_STOREBRANCH_BY_STORE, {
    refetchQueries: [
      {
        query: GET_STORE_BRANCHES,
        variables: { storeid: storeId },
        fetchPolicy: 'network-only',
      },
    ],
    awaitRefetchQueries: true,
  });

  /**
   * Handling Update Button Status
   */
  useEffect(() => {
    if (
      storeName === '' ||
      storeOwner === '' ||
      street === '' ||
      phone === '' ||
      zipcode === '' ||
      place === '' ||
      storeNameError.isError === true ||
      storeOwnerError.isError === true ||
      streetError.isError === true ||
      phoneError.isError === true ||
      zipcodeError.isError === true ||
      dataChanged === false
    ) {
      setIsButtonUpdateEnabled(false);
    } else {
      setIsButtonUpdateEnabled(true);
    }
  }, [
    storeName,
    storeOwner,
    street,
    zipcode,
    place,
    storeNameError.isError,
    storeOwnerError.isError,
    streetError.isError,
    zipcodeError.isError,
    dataChanged,
    phone,
    phoneError.isError,
  ]);

  /**
   * Vorhandene Branches holen und im state speichern.
   */
  useEffect(() => {
    if (
      !allBranchesLoading &&
      !storeBranchesLoading &&
      allBranchesData &&
      storeBranchesData
    ) {
      let tempChecked: any;
      let tempBranch: any;
      const tempBranches: Array<any> = [];
      for (let i = 0; i < allBranchesData.storebranch.length; i++) {
        tempChecked = false;
        for (let j = 0; j < storeBranchesData.storebranch.length; j++) {
          if (
            allBranchesData.storebranch[i].id ===
            storeBranchesData.storebranch[j].id
          ) {
            tempChecked = true;
          }
        }
        tempBranch = {
          branchId: allBranchesData.storebranch[i].id,
          branchName: allBranchesData.storebranch[i].branchname,
          checked: tempChecked,
        };
        tempBranches.push(tempBranch);
      }
      setAllBranches(tempBranches);
    }
  }, [
    allBranchesLoading,
    allBranchesData,
    storeBranchesLoading,
    storeBranchesData,
  ]);

  /**
   * Wenn storeName geändert wird.
   */
  const handleStoreNameInput = (e: any) => {
    setStoreName(e.target.value);
    setDataChanged(true);
    if (e.target.value !== '') {
      setStoreNameError({
        isError: false,
        message: '',
      });
    } else {
      setStoreNameError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
    }
  };

  /**
   * storeName Input Validierung
   */
  const checkStoreNameInput = () => {
    if (storeName === '') {
      setStoreNameError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
    } else {
      setStoreNameError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Wenn storeOwner geändert wird.
   */
  const handleStoreOwnerInput = (e: any) => {
    setStoreOwner(e.target.value);
    setDataChanged(true);
    const regexp = /^[\p{L} ,.'-]+$/u;
    if (e.target.value === '') {
      setStoreOwnerError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
    } else if (!regexp.test(e.target.value)) {
      setStoreOwnerError({
        isError: true,
        message: 'Eingabe ist fehlerhaft!',
      });
    } else {
      setStoreOwnerError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * storeOwner Input Validierung
   */
  const checkStoreOwnerInput = () => {
    if (storeOwner === '') {
      setStoreOwnerError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
    } else {
      setStoreOwnerError({
        isError: false,
        message: '',
      });
      setDataChanged(true);
    }
  };

  /**
   * Wenn zipcode geändert wird.
   */
  const handleZipcodeInput = async (e: any) => {
    setZipcode(e.target.value);
    const regexp = /^[0-9\b]+$/;
    if (e.target.value === '' || regexp.test(e.target.value)) {
      if (e.target.value.length >= 3) {
        // ab 3 Stellen wird Anfrage ausgelöst
        try {
          const { data } = await getLocations({
            variables: { param: e.target.value + '%' },
          });
          setZipcodeDropdownList(data.location);
          setZipcodeDropdownListIsSet(true);
          setDataChanged(true);
        } catch (error) {
          console.log('Error! in handleZipcodeInput: ', error);
        }
      }
      if (e.target.value.length >= 6) {
        setZipcodeError({
          isError: true,
          message: 'Bitte nur 5 Ziffern eingeben!',
        });
        setZipcodeDropdownList([]);
        setZipcodeDropdownListIsSet(false);
      } else {
        setZipcode(e.target.value);
      }
    } else {
      setZipcodeError({
        isError: true,
        message: 'Bitte nur Ziffern eingeben!',
      });
      setZipcodeDropdownList([]);
      setZipcodeDropdownListIsSet(false);
    }
  };

  /**
   * Wird beim "onPress" Event in Autocomplete Tag ausgelöst,
   * sobald ein Ort in der Liste ausgewählt wird.
   */
  const onSelectLocation = (item: any) => {
    if (item === null) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-sequences
      setStoreLocationId(0),
        setZipcode(''),
        setPlace(''),
        setZipcodeError({
          isError: true,
          message: 'Eingabe ist erforderlich!',
        });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-sequences
      setStoreLocationId(item.id),
        setZipcode(item.zipcode),
        setPlace(item.place),
        setZipcodeError({
          isError: false,
          message: '',
        });
    }
    setZipcodeDropdownListIsSet(false);
  };

  /**
   * Wenn street geändert wird.
   */
  const handleStreetInput = (e: any) => {
    setStreet(e.target.value);
    setDataChanged(true);
    if (e.target.value !== '') {
      setStreetError({ isError: false, message: '' });
    } else {
      setStreetError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
    }
  };

  /**
   * street Input Validierung
   */
  const checkStreetInput = () => {
    if (street === '') {
      setStreetError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
    } else {
      setStreetError({
        isError: false,
        message: '',
      });
      setDataChanged(true);
    }
  };

  /**
   * Event zum setzen der Telefonnummer im State.
   */
  const handlePhoneInput = (event: any) => {
    const regexp = /^([0-9()/+ -]*)$/;
    setPhone(event.target.value);
    setDataChanged(true);
    if (event.target.value === '') {
      setPhoneError({
        isError: true,
        message: 'Telefonnummer ist erforderlich!',
      });
    } else if (!regexp.test(event.target.value)) {
      setPhoneError({
        isError: true,
        message: 'Ist keine gültige Telefonnummer.',
      });
    } else {
      setPhoneError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * phone Input Validierung.
   */
  const checkPhoneInput = () => {
    const regexp = /^([0-9()/+ -]*)$/;
    if (phone === '') {
      setPhoneError({
        isError: true,
        message: 'Telefonnummer ist erforderlich!',
      });
    } else if (!regexp.test(phone)) {
      setPhoneError({
        isError: true,
        message: 'Ist keine gültige Telefonnummer.',
      });
    } else {
      setPhoneError({
        isError: false,
        message: '',
      });
    }
  };

  // Beim laden der Storedaten nichts anzeigen.
  if (storeLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage der Storedaten auftritt.
  if (storeError) {
    console.log(`Error! TenantStoreView: ${storeError.message}`);
  }

  // Beim laden nichts anzeigen.
  if (storeBranchesLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (storeBranchesError) {
    console.log(`Error! TenantStoreView: ${storeBranchesError.message}`);
  }

  // Beim laden nichts anzeigen.
  if (allBranchesLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (allBranchesError) {
    console.log(`Error! TenantStoreView: ${allBranchesError.message}`);
  }

  /**
   * Auswahl der Branches für das Geschäft.
   */
  const handleStoreBranches = (branchId: number, checkedState: boolean) => {
    const tempBranches: Array<any> = [];
    let checkedAvailable = 0;
    for (let i = 0; i < allBranches.length; i++) {
      if (allBranches[i].branchId === branchId && checkedState === true) {
        // add branches
        addStorebranches({
          variables: {
            branches: {
              store_id: storeId,
              storebranch_id: branchId,
            },
          },
        });
        //change the checkedState
        tempBranches.push({
          branchId: allBranches[i].branchId,
          branchName: allBranches[i].branchName,
          checked: checkedState,
        });
      } else if (
        allBranches[i].branchId === branchId &&
        checkedState === false
      ) {
        // remove branch
        removeStorebranch({
          variables: {
            storeid: storeId,
            storebranchid: branchId,
          },
        });
        //change the checkedState
        tempBranches.push({
          branchId: allBranches[i].branchId,
          branchName: allBranches[i].branchName,
          checked: checkedState,
        });
      } else {
        tempBranches.push(allBranches[i]);
      }
    }
    setAllBranches(tempBranches);
    for (let i = 0; i < tempBranches.length; i++) {
      if (tempBranches[i].checked === true) {
        checkedAvailable = checkedAvailable + 1;
      }
    }
  };

  // return
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
      style={{ width: '100%' }}
    >
      <Container maxWidth='xs'>
        {/** Branches List Dialog */}
        <Dialog
          open={branchesDialogVisible}
          onClose={() => setBranchesDialogVisible(false)}
          aria-labelledby='branches-dialog-title'
          aria-describedby='branches-dialog-description'
        >
          <DialogTitle id='create-dialog-title'>
            Branchen für das Geschäft auswählen.
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id='branches-dialog-description'
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {allBranches.map((storebranch: any) => (
                <FormControlLabel
                  key={storebranch.branchId}
                  control={
                    <Checkbox
                      checked={storebranch.checked}
                      onChange={() =>
                        handleStoreBranches(
                          storebranch.branchId,
                          !storebranch.checked
                        )
                      }
                      color='secondary'
                    />
                  }
                  label={storebranch.branchName}
                />
              ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{
                borderRadius: 30,
                width: 100,
                marginTop: 1,
                marginBottom: 1,
              }}
              onClick={() => {
                setIsStatusEdit(false);
                setBranchesDialogVisible(false);
              }}
              variant='contained'
              color='secondary'
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        {/** Branches List Dialog */}
        {/** Header */}
        <Box mt={3}>
          <Header2
            title='Geschäftsdaten'
            subtitle={`Sie sind angemeldet als ${profile.email}`}
          />
        </Box>
        {/** Header end */}
        {/** Content */}
        <Box
          p={2}
          pt={1}
          mt={2}
          sx={{ backgroundColor: theme.palette.primary.main }}
        >
          <TextField
            sx={{ width: '100%' }}
            color='secondary'
            variant='standard'
            margin='dense'
            label='Name des Geschäfts'
            value={storeName}
            error={storeNameError.isError}
            helperText={storeNameError.message}
            onChange={(e) => handleStoreNameInput(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                checkStoreNameInput();
              }
            }}
            onBlur={() => checkStoreNameInput()}
            inputProps={{
              readOnly: !isStatusEdit,
              autoComplete: 'none',
            }}
          />
          <TextField
            sx={{ width: '100%' }}
            color='secondary'
            variant='standard'
            margin='dense'
            label='Inhaber (Vor- und Nachname)'
            value={storeOwner}
            error={storeOwnerError.isError}
            helperText={storeOwnerError.message}
            onChange={(e) => handleStoreOwnerInput(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                checkStoreOwnerInput();
              }
            }}
            onBlur={checkStoreOwnerInput}
            inputProps={{
              readOnly: !isStatusEdit,
              autoComplete: 'none',
            }}
          />
          <Autocomplete
            sx={{ width: '100%' }}
            readOnly={!isStatusEdit}
            popupIcon={null}
            clearIcon={null}
            open={zipcodeDropdownListIsSet}
            inputValue={zipcode}
            options={zipcodeDropdownList}
            getOptionLabel={(option: any) =>
              `${option.zipcode} ${option.place}`
            }
            isOptionEqualToValue={(option: any, value: any) =>
              option.zipcode === value.zipcode && option.place === value.place
            }
            onChange={(e: object, value: any | null) => {
              onSelectLocation(value);
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                sx={{ width: '100%' }}
                color='secondary'
                variant='standard'
                margin='dense'
                label='PLZ'
                required
                onChange={(e) => handleZipcodeInput(e)}
                helperText={zipcodeError.message}
                FormHelperTextProps={{
                  sx: { color: theme.palette.secondary.main },
                }}
              />
            )}
          />
          <TextField
            sx={{ width: '100%' }}
            color='secondary'
            variant='standard'
            margin='dense'
            label='Ort'
            value={place}
            inputProps={{ readOnly: true }}
          />
          <TextField
            sx={{ width: '100%' }}
            color='secondary'
            variant='standard'
            margin='dense'
            required
            label='Straße'
            value={street}
            error={streetError.isError}
            helperText={streetError.message}
            onChange={(e) => handleStreetInput(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                checkStreetInput();
              }
            }}
            onBlur={checkStreetInput}
            inputProps={{
              readOnly: !isStatusEdit,
              autoComplete: 'none',
            }}
          />
          <TextField
            sx={{ width: '100%' }}
            color='secondary'
            variant='standard'
            margin='dense'
            required
            label='Telefon'
            value={phone}
            error={phoneError.isError}
            helperText={phoneError.message}
            onChange={(e) => handlePhoneInput(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                checkPhoneInput();
              }
            }}
            onBlur={checkPhoneInput}
            inputProps={{
              readOnly: !isStatusEdit,
              autoComplete: 'none',
            }}
          />
          {storeBranchesData && storeBranchesData.storebranch.length === 0 ? (
            <>
              <TextField
                sx={{ width: '100%', input: { color: 'red' } }}
                color='secondary'
                variant='standard'
                margin='dense'
                label='Branche'
                value='Mindestens eine Branche ist erforderlich!'
                InputProps={{
                  readOnly: !isStatusEdit,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        disabled={!isStatusEdit}
                        color='inherit'
                        size='small'
                        onClick={() => setBranchesDialogVisible(true)}
                      >
                        <AiOutlineEdit size='22' />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </>
          ) : (
            <>
              {storeBranchesData &&
                storeBranchesData.storebranch.length > 0 &&
                storeBranchesData.storebranch.map((branch: any) => (
                  <TextField
                    sx={{ width: '100%' }}
                    key={branch.id}
                    color='secondary'
                    variant='standard'
                    margin='dense'
                    label='Branche'
                    value={branch.branchname}
                    InputProps={{
                      readOnly: !isStatusEdit,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            disabled={!isStatusEdit}
                            color='inherit'
                            size='small'
                            onClick={() => setBranchesDialogVisible(true)}
                          >
                            <AiOutlineEdit size='22' />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ))}
            </>
          )}
        </Box>
        {/** Content end */}
        {/** Action */}
        <Box
          mt={3}
          mb={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isStatusEdit === true ? (
            <Button
              sx={{
                borderRadius: 30,
                width: '240px',
              }}
              type='submit'
              disabled={!isButtonUpdateEnabled}
              variant='contained'
              color='secondary'
              onClick={() => {
                updateStore({
                  variables: {
                    id: storeId,
                    location_id: storeLocationId,
                    storename: storeName,
                    storeowner: storeOwner,
                    street: street,
                    phone: phone,
                  },
                });
                setIsStatusEdit(false);
                setDataChanged(false);
              }}
            >
              Übernehmen
            </Button>
          ) : (
            <Button
              sx={{
                borderRadius: 30,
                width: '240px',
              }}
              type='submit'
              variant='contained'
              color='secondary'
              onClick={() => setIsStatusEdit(true)}
            >
              Bearbeiten
            </Button>
          )}
          <Button
            sx={{
              marginTop: 2,
              borderRadius: 30,
              width: '240px',
            }}
            type='submit'
            variant='contained'
            color='secondary'
            onClick={() =>
              navigate('/tenant', {
                state: {
                  helpopen: helpOpen,
                },
              })
            }
          >
            Ok
          </Button>
        </Box>
        {/** Action end */}
      </Container>
      {helpOpen ? (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
          }}
          style={{ backgroundColor: '#9E9E9E', width: '20%' }}
        >
          <Box
            sx={{
              paddingTop: 1,
              paddingLeft: 1.5,
            }}
          >
            <IconButton onClick={() => setHelpOpen(false)}>
              <AiOutlineClose size='24' />
            </IconButton>
          </Box>
          <TenantStoreHelpView />
        </Box>
      ) : (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
            paddingRight: 1.5,
            paddingTop: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => setHelpOpen(true)}>
              <AiOutlineQuestionCircle size='24' />
            </IconButton>
            <Typography
              onClick={() => setHelpOpen(true)}
              paragraph
              component='span'
              sx={{
                color: theme.palette.text.primary,
                '&:hover, &:focus, &:active': {
                  textDecoration: `underline ${theme.palette.text.primary}`,
                },
              }}
            >
              Hilfe
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TenantStoreView;
