import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  useTheme,
} from '@mui/material';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { GET_REGULAR_CUSTOMERS } from '../data/FavoriteData';
import { GET_STORE_BY_TENANT_ID } from '../data/StoreData';
import { SEND_NEWS } from '../data/NewsData';
import { IInputError } from '../interfaces/interfaces';
import { useQuery, useMutation } from '@apollo/client';
import Header2 from '../components/Header2';
import { AiOutlineQuestionCircle, AiOutlineClose } from 'react-icons/ai';
import TenantRegularCustomerHelpView from './help/TenantRegularCustomerHelpView';
import { useAuth } from '../authContext';
import axios from 'axios';
import { Config } from '../config';

/**
 * TenantRegularCustomerView stellt die Liste
 * der Stammkunden eines Tenants dar.
 */
const TenantRegularCustomerView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const routerLocation: any = useRouterLocation();
  const [{ profile }] = useAuth();
  const [allCustomersAvailable, setAllCustomersAvailable] =
    useState<boolean>(false);
  const [regularCustomersAvailable, setRegularCustomersAvailable] =
    useState<any>([]);
  const [messageSelectable, setMessageSelectable] = React.useState<string>('');
  const [messageOther, setMessageOther] = useState<string>('');
  const [messageCurrent, setMessageCurrent] = useState<string>('');
  const [newsCustomers, setNewsCustomers] = useState<any>([]);
  const [messageOtherError, setMessageOtherError] = useState<
    Partial<IInputError>
  >({
    isError: false,
    message: '',
  });
  const [messageEmptyDialogVisible, setMessageEmptyDialogVisible] =
    useState<boolean>(false);
  const [sendDialogVisible, setSendDialogVisible] = useState<boolean>(false);
  const [customersEmptyDialogVisible, setCustomersEmptyDialogVisible] =
    useState<boolean>(false);
  const [helpOpen, setHelpOpen] = useState<boolean>(
    routerLocation.state.helpopen
  );
  const NODEMAILER_URL: any =
    process.env.REACT_APP_NODEMAILER_URL !== undefined
      ? process.env.REACT_APP_NODEMAILER_URL
      : Config.nodemailer_url;

  // get regular customerrs
  const {
    loading: customersLoading,
    error: customersError,
    data: customersData,
  } = useQuery(GET_REGULAR_CUSTOMERS, {
    variables: { storeid: routerLocation.state.storeId },
    fetchPolicy: 'network-only',
  });

  // get store by tenantId
  const {
    loading: storeLoading,
    error: storeError,
    data: storeData,
  } = useQuery(GET_STORE_BY_TENANT_ID, {
    variables: { tenantid: profile.tenant_id },
    fetchPolicy: 'network-only',
  });

  // send news
  const [sendNews] = useMutation(SEND_NEWS);

  /**
   * Stammkunden holen und setzen.
   */
  useEffect(() => {
    if (customersData) {
      const checked = false;
      const tempData: Array<any> = [];
      for (let i = 0; i < customersData.favorite.length; i++) {
        tempData.push({
          email: customersData.favorite[i].customer.account.email,
          checked,
        });
      }
      setRegularCustomersAvailable(tempData);
    }
  }, [customersData]);

  // Beim laden der Daten nichts anzeigen.
  if (customersLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (customersError) {
    console.log(`Error! TenantRegularCustomerView: ${customersError.message}`);
  }

  // Beim laden der Daten nichts anzeigen.
  if (storeLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (storeError) {
    console.log(`Error! TenantRegularCustomerView: ${storeError.message}`);
  }

  /**
   * Den Status für die Messages für alle setzen.
   */
  const handleAllRegularCustomers = (status: boolean) => {
    const tempArray: Array<any> = [];
    for (let i = 0; i < regularCustomersAvailable.length; i++) {
      tempArray.push({
        email: regularCustomersAvailable[i].email,
        checked: !status,
      });
      setRegularCustomersAvailable(tempArray);
    }
    setAllCustomersAvailable(!status);
  };

  /**
   * Den Status für die Messages einzeln setzen.
   */
  const handleRegularCustomer = (customer: any) => {
    const tempArray: Array<any> = [];
    for (let i = 0; i < regularCustomersAvailable.length; i++) {
      if (regularCustomersAvailable[i].email === customer.email) {
        tempArray.push({
          email: regularCustomersAvailable[i].email,
          checked: !regularCustomersAvailable[i].checked,
        });
        setAllCustomersAvailable(false);
      } else {
        tempArray.push(regularCustomersAvailable[i]);
      }
      setRegularCustomersAvailable(tempArray);
    }
  };

  /**
   * Handle MessageSelectable
   */
  const handleMessageSelectableChange = (event: any) => {
    if (event.target.value === 'other') {
      setMessageSelectable(event.target.value);
    } else {
      setMessageSelectable(event.target.value);
      setMessageOther('');
      setMessageOtherError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * OnFocus reset
   */
  const handleMessageOtherOnFocus = () => {
    setMessageOtherError({
      isError: false,
      message: '',
    });
  };

  /**
   * Event zu setzen der messages im State.
   * @param event
   */
  const handleMessageOtherChange = (event: any) => {
    setMessageOther(event.target.value);
  };

  /**
   * Input Validierung
   */
  const checkMessageOtherInput = () => {
    if (messageOther === '') {
      setMessageOtherError({
        isError: true,
        message: 'Nachrichtentext ist erforderlich!',
      });
    } else {
      setMessageOtherError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Funktion zum versenden der E-Mail.
   */
  const sendEmail = (toemail: string, message: string) => {
    axios({
      baseURL: NODEMAILER_URL,
      method: 'POST',
      url: '/news',
      data: {
        storename: storeData.store[0].storename,
        // eslint-disable-next-line no-useless-concat
        fromemail: storeData.store[0].storename + '<' + profile.email + '>',
        toemail,
        subject: storeData.store[0].storename + ' - News',
        message: message,
      },
    }).then((response) => {
      if (response.data.status === 'success') {
        //alert('Message Sent.');
        //resetForm();
      } else if (response.data.status === 'fail') {
        console.log('Message failed to send.');
      }
    });
  };

  /**
   * Dialog für das versenden der News Letters.
   */
  const handleSendDialog = (action: string) => {
    if (action === 'send') {
      sendNews({ variables: { objects: newsCustomers } });
      setMessageSelectable('');
      setMessageOther('');
      setAllCustomersAvailable(false);
      setSendDialogVisible(false);
      // emaels versenden
      if (newsCustomers) {
        for (let i = 0; i < newsCustomers.length; i++) {
          sendEmail(newsCustomers[i].message_to, newsCustomers[i].message);
        }
      }
      // alles zurücksetzen
      if (customersData) {
        const checked = false;
        const tempData: Array<any> = [];
        for (let i = 0; i < customersData.favorite.length; i++) {
          tempData.push({
            email: customersData.favorite[i].customer.account.email,
            checked,
          });
        }
        setRegularCustomersAvailable(tempData);
      }
    } else {
      setSendDialogVisible(false);
    }
  };

  /**
   * Beim senden der News wird alles überprüft
   * und dann gesendet.
   */
  const handleMessagesSend = () => {
    let customers = 0;
    let tempmessage = '';
    const tempnews: Array<any> = [];
    for (let i = 0; i < regularCustomersAvailable.length; i++) {
      if (regularCustomersAvailable[i].checked === true) {
        customers = customers + 1;
      }
    }
    // keine Empfänger gesetzt
    if (customers === 0) {
      setCustomersEmptyDialogVisible(true);
    }
    // ein oder mehr Empfänger vorhanden
    if (customers > 0) {
      // wenn Nachricht leer ist
      if (
        messageSelectable === '' ||
        (messageSelectable === 'other' && messageOther === '')
      ) {
        setMessageEmptyDialogVisible(true);
      } else {
        if (messageSelectable === 'other') {
          tempmessage = messageOther;
          setMessageCurrent(messageOther);
        } else {
          tempmessage = messageSelectable;
          setMessageCurrent(messageSelectable);
        }
        for (let i = 0; i < regularCustomersAvailable.length; i++) {
          if (regularCustomersAvailable[i].checked === true) {
            tempnews.push({
              store_id: routerLocation.state.storeId,
              message_to: regularCustomersAvailable[i].email,
              message: tempmessage,
            });
          }
        }
        setNewsCustomers(tempnews);
        setSendDialogVisible(true);
      }
    }
  };

  // return
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
      style={{ width: '100%' }}
    >
      <Container maxWidth='xs'>
        {/** Customer Empty Dialog */}
        <Dialog
          open={customersEmptyDialogVisible}
          onClose={() => setCustomersEmptyDialogVisible(false)}
          aria-labelledby='customers-empty-dialog-title'
          aria-describedby='customers-empty-dialog-description'
        >
          <DialogTitle id='customers-empty-dialog-title'>
            Empfänger erforderlich!
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='customers-empty-dialog-description'>
              Wählen Sie die Empfänger der Nachricht aus der Liste aus.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => setCustomersEmptyDialogVisible(false)}
              variant='outlined'
              color='inherit'
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        {/** Customer Empty Dialog end */}
        {/** Message Empty Dialog */}
        <Dialog
          open={messageEmptyDialogVisible}
          onClose={() => setMessageEmptyDialogVisible(false)}
          aria-labelledby='message-empty-dialog-title'
          aria-describedby='message-empty-dialog-description'
        >
          <DialogTitle id='message-empty-dialog-title'>
            Nachricht erforderlich!
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='message-empty-dialog-description'>
              Wählen Sie eine Nachricht aus der Liste aus oder schreiben Sie
              eine eigene.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => setMessageEmptyDialogVisible(false)}
              variant='outlined'
              color='inherit'
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        {/** Message Empty Dialog end */}
        {/** Send Dialog */}
        <Dialog
          open={sendDialogVisible}
          onClose={() => setSendDialogVisible(false)}
          aria-labelledby='send-dialog-title'
          aria-describedby='send-dialog-description'
        >
          <DialogTitle id='send-dialog-title'>News senden!</DialogTitle>
          <DialogContent>
            <DialogContentText id='send-dialog-description'>
              Möchten Sie die folgende Nacricht {'"'}
              {messageCurrent}
              {'"'} senden?
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => handleSendDialog('cancel')}
              variant='outlined'
              color='inherit'
            >
              Abbrechen
            </Button>
            <Button
              onClick={() => handleSendDialog('send')}
              variant='outlined'
              color='inherit'
              autoFocus
            >
              Senden
            </Button>
          </DialogActions>
        </Dialog>
        {/** Send Dialog end */}
        {/** Header */}
        <Box mt={3}>
          <Header2
            title='Die Liste Ihrer Stammkunden.'
            subtitle='Hier haben Sie die Möglichkeit Ihre Stammkunden über neue, reduzierte
          Artikel oder etwas anderes zu informieren.'
          />
        </Box>
        {/** Header end */}
        {/** Content */}
        {customersData.favorite.length === 0 ? (
          <Box sx={{ mt: 2 }}>
            <Typography
              component='h2'
              variant='subtitle1'
              mb={1}
              align='center'
            >
              Keine Stammkunden vorhanden.
            </Typography>
          </Box>
        ) : (
          <Box sx={{ mt: 2 }}>
            <FormControl component='fieldset' sx={{ width: '100%', mb: 1 }}>
              <Box
                sx={{
                  p: 0.2,
                  pl: 1,
                  backgroundColor: theme.palette.primary.main,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allCustomersAvailable}
                      onChange={() =>
                        handleAllRegularCustomers(allCustomersAvailable)
                      }
                      color='secondary'
                    />
                  }
                  label='Alle auswählen?'
                />
              </Box>
              {regularCustomersAvailable.map((regularCustomer: any) => (
                <FormGroup key={regularCustomer.email}>
                  <Box
                    sx={{
                      p: 0.2,
                      pl: 1,
                      backgroundColor: theme.palette.primary.main,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={regularCustomer.checked}
                          onChange={() =>
                            handleRegularCustomer(regularCustomer)
                          }
                          color='secondary'
                        />
                      }
                      label={regularCustomer.email}
                    />
                  </Box>
                </FormGroup>
              ))}
            </FormControl>
            <FormControl
              component='fieldset'
              sx={{
                width: '100%',
                p: 0.2,
                pl: 1,
                mb: 1,
                backgroundColor: theme.palette.primary.main,
              }}
            >
              <Typography
                component='h2'
                variant='body1'
                color='textPrimary'
                sx={{ mb: 1 }}
              >
                Folgende Nachricht senden:
              </Typography>
              <RadioGroup
                aria-label='message'
                name='messageGroup'
                value={messageSelectable}
                onChange={handleMessageSelectableChange}
              >
                <FormControlLabel
                  value='Viele neue Artikel im Angebot.'
                  control={<Radio color='secondary' />}
                  label='Viele neue Artikel im Angebot.'
                />
                <FormControlLabel
                  value='Viele Artikel im Preis reduziert.'
                  control={<Radio color='secondary' />}
                  label='Viele Artikel im Preis reduziert.'
                />
                <FormControlLabel
                  value='other'
                  control={<Radio color='secondary' />}
                  label='Etwas anderes'
                />
              </RadioGroup>
            </FormControl>
            <TextField
              sx={{ width: '100%', background: theme.palette.primary.main }}
              variant='outlined'
              multiline
              rows={8}
              value={messageOther}
              error={messageOtherError.isError}
              helperText={messageOtherError.message}
              FormHelperTextProps={{
                sx: { color: theme.palette.secondary.main },
              }}
              onFocus={handleMessageOtherOnFocus}
              onChange={handleMessageOtherChange}
              onBlur={checkMessageOtherInput}
            />
            {/** Content */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                sx={{
                  borderRadius: 30,
                  width: '240px',
                  mt: 3,
                }}
                type='submit'
                variant='contained'
                color='secondary'
                onClick={() => handleMessagesSend()}
              >
                Senden
              </Button>
            </Box>
          </Box>
        )}
        {/** Action */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            type='submit'
            sx={{
              mt: 2,
              mb: 3,
              borderRadius: 30,
              width: '240px',
            }}
            variant='contained'
            color='secondary'
            onClick={() =>
              navigate('/tenant', {
                state: { helpopen: helpOpen },
              })
            }
          >
            Ok
          </Button>
        </Box>
        {/** Action end */}
      </Container>
      {helpOpen ? (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
          }}
          style={{ backgroundColor: '#9E9E9E', width: '20%' }}
        >
          <Box
            sx={{
              paddingTop: 1,
              paddingLeft: 1.5,
            }}
          >
            <IconButton onClick={() => setHelpOpen(false)}>
              <AiOutlineClose size='24' />
            </IconButton>
          </Box>
          <TenantRegularCustomerHelpView />
        </Box>
      ) : (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
            paddingRight: 1.5,
            paddingTop: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => setHelpOpen(true)}>
              <AiOutlineQuestionCircle size='24' />
            </IconButton>
            <Typography
              onClick={() => setHelpOpen(true)}
              paragraph
              component='span'
              sx={{
                color: theme.palette.text.primary,
                '&:hover, &:focus, &:active': {
                  textDecoration: `underline ${theme.palette.text.primary}`,
                },
              }}
            >
              Hilfe
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TenantRegularCustomerView;
